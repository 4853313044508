  .Bigheart {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    z-index: 0;
  }
  
  .smallHearts {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 27px;
    height: 27px;
    z-index: 0;
  
  }
  #heartWrapper {
    position: relative;
    z-index: 80;
    button {
      border-width: 2px !important;
        position: relative;
        z-index: 1;
    }
   
  }
  #heartContainer {
    bottom: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: visible;
  z-index:0;
  svg {
    width: auto;
    height: auto;
}
  }
  .confettiWrapper {
    position: relative;
    height: 100%;
  
  }
  
  #confettiContainer {
    bottom: 0px;
    left: 0px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    text-align: center;
    overflow: visible;
    svg {
        width: auto;
        height: auto;
    }
    }
  
  
  #confetti, #confetti2 {
    background: transparent;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: visible;
    canvas {
    opacity: 0;
    }
  }