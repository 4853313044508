 .header-filter {
   padding: 0px !important;
 }
.menu-wrapper {
  position: relative;
  .monoserat-bold {
    font-family: $f_btn;
    font-size: 16px !important;

  }
 
  .menu-items-wrapper {
    @extend %nav;
    min-width: 280px;
    height: 100%;
    width: 0;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 3;
    overflow-x: hidden;
    transition: 0.3s;
    padding-left: 30px;
    padding-top: 57px;
    background: white;
    transition: transform 300ms ease-in-out 200ms;
    transform: translateX(-100%);
    // display: flex;
    	box-sizing: border-box;

    .menu-items-outer {
      display: none;
      position: relative;
      width: 100%;
      // height: 60vh;
      z-index: 1;
    }

    .menu-items-inner {
      width: 100%;

      .menu-item {
        @extend %h4;
        @extend %greyline;
        display: block;
        padding-top: 12px;
        padding-bottom: 16px;
        width: 100%;
        line-height: 24px;

        &.menu-item-wicon {
          display: flex;
          align-items: center;
          // justify-content: space-between;
        }
      }

      svg {
        padding-left: 7px;
        margin-bottom: 12px;
        width: 14px;
        height: 14px;
      }

      .accordionwrapper {
        width: 100%;
        line-height: 24px;

        .acctitle {
          @extend %h4;
          padding-top: 12px;
          padding-bottom: 16px;
          text-transform: none;
        }


        .accordion-item__content {
          width: 100%;

          .menu-items-inner {
            padding-left: 20px;
          }
        }

        .form-section-header {
          display: flex;
          @extend %greyline;

        //   .arrowicon {
        //     height: 18px;
        //     overflow: hidden;
        //     position: absolute;
        //     left: 220px;
        //     top: 18px;
        //     width: 18px;
        //     transform: rotate(-45deg);
        //     transition: transform 0.3s ease-in-out;
        //   }

        //   .accordion-item__icon {
        //     // position: absolute;
        //     width: 12px;
        //     height: 12px;
        //     // left: 220px;
        //     // top: 16px;
        //     border-left: 1px solid $c_accent_3;
        //     // transform: rotate(-45deg);
        //     border-bottom: 1px solid $c_accent_3;
        //     align-self: center;
        //     background: none !important;
        //     display: block;
        //   }
        // }
        // .accordion-item--opened {
        //   .arrowicon {
        //     transform: rotate(135deg);
        //   }
        //   .accordion-item__icon {
        //     transform: none;
        //   }
        }

        li {
          padding: 0px;
        }
      }

      .menusmall {
        @extend %heading;
        font-family: $f_nav;
        font-size: 20px;
        line-height: 20px;
        font-weight: 400;
        text-transform: none;
        padding-top: 12px;
        padding-bottom: 16px;
        width: 100%;
        cursor: pointer;
        // line-height: 56px;
      }

      a,
      button {
        @extend %alt_link;
        // font-family: $f_title;
        text-transform: capitalize;
        // font-size: 40px;
      }
    }
  }

  .menu-items-outer {
    pointer-events: none;
    z-index: -1;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
    transition: opacity 300ms ease-in-out 300ms;
  }
 
     .filter-menu {
       left: auto;
       right: 0px;
       padding-top: 25px;
       padding-right: 23px;
       padding-left: 23px;
       .brandlist {
        svg {
          padding-left: 0px;
          margin-bottom: 0px;
        }
       }
       .menu-item, h2 {
        text-transform: capitalize !important;
       }
       .close {
  
         width: 20px !important;
         height: 30px !important;
  
       }
     }

  .menu-trigger {
    font-size: 12px;

    #menu-open {
      display: block;
      width: 30px;
    }

    #menu-close {
      display: none;
    }

    .icon {
      width: 27px;
    }
  }

  &.toggled {
    .slidingmenu {
      .menu-items-outer {
        width: 100%;
        opacity: 0.8;
        pointer-events: all;
        z-index: 0;
      }

      .menu-items-wrapper {
        transform: translateX(0%);

        @at-root body {
          &.scrolled {
            .header_wrapper .menu-wrapper.toggled .menu-items-wrapper {
              top: 33px;
            }
          }
        }
      }
    }

    .menu-trigger {
      #menu-open {
        display: none;
        cursor: pointer;
      }
    }
  }
}
