.def-form {

  max-width: 600px !important;

  .remove-row {
    width: 60px;
  }

  .field-wrap.toggle-wrap {
    flex: 1 !important;

    .remove-row {
      width: 30px !important;
      padding-left: 15px;
      position: relative !important;
      top: 0px !important;
    }
  }

  .subcat-wrapper {
    .label-wrap {
      flex: 0 1 53px !important;
    }
  }

}