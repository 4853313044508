.mega-dashboard {
	// overflow-x: scroll;
}

.site-content {
	overflow: unset;
	// padding-right: 0px;
}

.shopperapply {
	tbody {
		vertical-align: initial;
	}
}

.nopadding {
	padding: 0px !important
}

.whitespace-wrap {
	white-space: normal;
}

%tableimg {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: 8px;
  box-sizing: border-box;
	// border: 1px solid #e5e4e4;

}

%tablethumb {
	cursor: pointer;
	object-position: center;
	position: absolute;
	max-width: 100%;
	width: 100%;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.stickyleft {
	position: sticky;
	/* z-index: 3; */
	left: 30px;
}
.stickycontain {
	display: flex;
	flex-direction: column;
	/* flex-wrap: wrap; */
	/* align-content: flex-start; */
	/* justify-content: flex-start; */
	align-items: flex-start;
	/* width: 100%; */
	/* flex: auto; */
	/* flex-basis: 100%; */
	/* flex-grow: 1; */
	/* box-sizing: content-box; */
	/* align-items: stretch; */
	/* flex: 1; */
	overflow: scroll;
}

.tablecontainer {
	border-spacing: 0;
	max-width: 100vw;
	overflow: scroll;
}

.mega-dashboard-table {

	td {
		white-space: nowrap;
	}

	&.blocktable {
		padding: 1rem;
		width: fit-content;
		display: inline-block;
		max-width: 100%;
		overflow: scroll;

	}

	&::-webkit-scrollbar {
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: $lab_grey_0;
	}

	&::-webkit-scrollbar-thumb {
		// background: $lab_grey_0;
		background: $c_accent_3;
	}

	.page-header {
		margin-bottom: 20px;
	}

	// overflow: auto;
	// position: relative;
	border-spacing: 0;

	.checked {
		width: 28px;
		height: 26px;
	}

	.unchecked {
		width: 23px;
		height: 23px;
	}

	.bg-lab-grey-0 {
		background: $lab_grey_0;
	}
	.bg-white {
		background-color: white;
	}

	.table,
	table {
		// text-align: left;
		// position: relative;
		width: 100%;
		border-collapse: collapse;
		overflow: scroll;

		.tr {
			:last-child {
				.td {
					border-bottom: 0;
				}
			}
		}

		.th,
		.td {
			padding: 5px;
			border-bottom: 1px solid #ddd;
			border-right: 1px solid #ddd;
			background-color: #fff;
			overflow: hidden;

		

			:last-child {
				border-right: 0;
			}
		}


		&.sticky {
			overflow: scroll;

			.header,
			.footer {
				position: sticky;
				z-index: 1;
				width: fit-content;
			}

			.header {
				top: 0;
				// box-shadow: 0px 3px 3px #ccc;
			}

			.footer {
				bottom: 0;
				// box-shadow: 0px -3px 3px #ccc;
			}

			.body {
				position: relative;
				z-index: 0;
			}

			[data-sticky-td] {
				position: sticky;
				background: #f2f2f2;
				border-bottom: 1px solid white;
				min-width: 40px;
			}

			[data-sticky-last-left-td] {
				box-shadow: 2px 0px 3px #ccc;
			}

			[data-sticky-first-right-td] {
				box-shadow: -2px 0px 3px #ccc;
			}
		}

	}

	.th,
	th {
		background: $lab_grey_0;
		// position: sticky;
		// top: 45px;
		z-index: 2;
	}

	//   th[scope=column] {
	//     min-height: 60px;
	//     position: sticky;
	//     left: 0;
	//     z-index: 1;
	//   }

	thead,
	tfoot {
		&.sticky {
			// position: fixed;
			// top: 45px;
		}

		// @extend %t_body;
		// background: $lab_grey_0;

		tr {
			&.filtersRow {

				th {
					padding: 8px;
					text-align: left;
					font-size: 17px;
					line-height: 1.2em;
					font-weight: 500;
					// min-height: 20px;
					// &.col-details {
					// min-width: 400px !important;
					// }
				}
				.icon {
					margin-left: 5px;
						margin-top: 5px;
				}
				.sortArrow {
					cursor: pointer;
					&:hover {

						// border-bottom: 1px solid #999
					}
					// &::after {
					// 	content: "⇵";
					// 	display: inline-block;
					// 	width: 15px;
					// 	height: 15px;
					// 	font-size: 14px;
						
					// 	// background: url($sortArrow) no-repeat;
					// 	// background-size: 15px 15px;
					// 	// background-position: center;
					// 	margin-left: 2px;
						
					// }
				}
			}

			th {
				// @extend %t_body;
				// white-space: nowrap;
				border: none;

				// &.col-seller,
				// &.col-name,
				// &.col-country {
				// 	min-width: 120px;
				// }


				&.col-address,
				&.col-description,
				&.col-reason,
				&.col-details {
					min-width: 200px;
				}
			


				&.col-why {
					min-width: 500px;
				}
				
				 > 	div {
						text-align: left;
						min-width: 58px;
						display: flex;
						align-items: center;
						// height: 33px;
					}
				

				&.col-selection, &.col-overview {
					>	div {
						justify-content: center;
							min-width: initial !important;
						}
					}
			

				>div>span {
					// display: inline-block;
					width: 15px;
					height: 15px;
					margin-left: 5px;
					display: none;

					svg {
						width: 15px;
						height: 15px;
					}
				}
			}

		}
	}

	.tbody,
	.thead,
	tbody,
	thead {
		tr {
			&:nth-child(2n) {
				background: #fafafa;
			}

		}

		td,
		th {
			min-height: 56px;
			padding: 8px 8px;
			box-sizing: border-box;
			  // min-width: 87px;

			span.highlight {
				background: #f5a0a0;
				padding: 5px;
			}
		}
	}



	button {
		background: #fff !important;
		// border: 1px solid #000 !important;
		border-radius: 2em 2em 2em 2em !important;
		color: #000 !important;
		line-height: 2em !important;
		padding: 0px 10px;
	}
}

$nice-dates-color-gray-dark: #333;
$nice-dates-color-gray: #999;
$nice-dates-color-gray-light: #ddd;
$nice-dates-color-accent: $nice-dates-color-gray-dark;
$nice-dates-font-size-small: 12px;
$nice-dates-font-size-base: 14px;
$nice-dates-font-size-big: 16px;
@import 'react-nice-dates/src/style.scss';

.col-createdAt {
	z-index: 9 !important;
}

.table {

	.imgtoggle {
		@extend %tableimg;

		img.thumb {
			@extend %tablethumb;
		}
	}

	.nice-dates {
		z-index: 9;
		max-width: 400px;
		position: relative;


		input.input {
			width: 80px;
			font-size: 14px;
			text-align: center;
			border: 1px solid black !important;
			background: white;
			padding: 0px 10px;
		}
	}






	span.nice-dates-day_date {
		// line-height: .5em;
	}

	.nice-dates-popover.-open {
		// max-width: 310px;
	}

}

.brandlist {
	column-gap: 15px;
	/* align-items: stretch; */
	display: flex;
	/* flex-flow: wrap; */
	/* justify-content: space-between; */
	/* justify-items: flex-start; */
	/* max-width: 354
px
; */
	/* flex-shrink: 1; */
	/* flex-grow: 0; */
	/* justify-content: flex-start; */
	/* flex-basis: 100%; */
	/* flex-direction: row; */
	flex-flow: wrap;
	//  > div {
	// // 	 flex-shrink: 1; */
	// // flex-grow: 0;
	// /* justify-content: unset; */
	// /* flex: 1 0; */
	// /* flex-basis: max-content; */
	// /* flex-basis: 49%; */
	// /* flex-direction: row; */
	// // min-width: 354px
	//  }
}

// for SA Listings 

.sa-listings-row {
	.stats {
					// @include flex(wrap, center, center);
					// font-size: 19px;
					// line-height: 22px;
					text-align: center;
					flex-wrap: wrap;
					.size-span {
							margin-right: 4px;
						}
			
					& > div {
						@include flex(row, center, start);
						position: relative;
							flex-wrap: wrap;
						&::before {
							content: '|';
							display: block;
							margin: 0 5px;
							color: $c_accent_4;
						}
			
						&:nth-of-type(1) {
							&::before {
								display: none;
							}
						}
					}

				
		// div {
		// 		border-right: 1px solid $c_accent_3;
		// 			padding: 0px 15px !important;

		// 			&:last-of-type {
		// 				border: none;
		// 			}
		// 			&:first-of-type {
		// 				padding-left: 0px;
		// 			}
		// }
	}
}