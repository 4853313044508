
.products-grid {
    @include flex(wrap, start, start);
    margin: 0 -10px;
    .centeredbox.txt.form {
        text-align: center;
        position: absolute;
        width: 100%;
        margin: 0 auto;
        left: 0;
        right: 0px;
        top: 40%;
    }
    .product-wrapper {
        // width: 12.5%;
        // margin-bottom: 48px;
        // .seller-products: {

        // }
      flex: 1 1 261px;
    //   max-width: 261px;
     max-width: 25%;
    //   @include respond-to(desktop, min){
    //         max-width: 25%;
    //     }
          @include respond-to(desktop, max){
            max-width: 25%;
        }
        //   @include respond-to(xlarge, min){
        //     max-width: 25%;
        // }
        @include respond-to(large, max){
            max-width: 33%;
        }

        @include respond-to(medium, max){
            max-width: 50%;
        }
    }
}

.products-grid{ 
    .steals{
        width:100%;
        margin-bottom:-70px !important;
        @include flex(wrap, start, start);
        margin: 0 4px;
        .centeredbox.txt.form {
            text-align: center;
            position: absolute;
            width: 100%;
            margin: 0 auto;
            left: 0;
            right: 0px;
            top: 40%;
        }
        .product-wrapper {
            // width: 12.5%;
            // margin-bottom: 40px;
            // .seller-products: {

            // }
        flex: 1 1 261px;
        //   max-width: 261px;
        max-width: 33%;
        //   @include respond-to(desktop, min){
        //         max-width: 25%;
        //     }
            @include respond-to(desktop, max){
                max-width: 33%;
            }
            //   @include respond-to(xlarge, min){
            //     max-width: 25%;
            // }
            @include respond-to(large, max){
                max-width: 33%;
            }

            @include respond-to(medium, max){
                max-width: 33%;
            }
        }
    }
}


    
