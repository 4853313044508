.header_wrapper {
    @extend %adjust-center;
    @extend %t_body_xs;
    background: $c_secondary;
    padding: 13px 30px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    &.zindex101 {
        z-index: 101
    }

    #logo_wrapper {

        flex: auto;

        a {
            line-height: 0px;
        }

        svg {
            width: 38px;
            max-width: 38px;
            height: auto;
            transition: width .2s ease-out;
            will-change: width;
        }
    }

    .account_wrapper {
        color: $c_primary;

        .account_head_name {

            padding-right: 6px;


            &.no-padding {
                padding-right: 0px;
            }
        }

        svg {
            width: auto;
            height: 24px;
            transition: height .2s ease-out;
            will-change: height;
            margin-right: -3px; // offset svg whitespace
        }

        &.isSeller, &.isSA {
            color: $c_link;
        }
    }
}


.sticky-wrapper {
    position: relative;
    z-index: 100;

}

.logo-small #logo_wrapper svg {
    /* height: 28px; */
    width: 26px;
}

.sticky {
    position: sticky;
    top: 0;

    &.table-stick {
        z-index: 99;
        top: 44px;
    }

    #logo_wrapper svg {
        /* height: 28px; */
        // width: 26px;
    }

    .sticky-inner {
        position: fixed;
        top: 0;

        //   .toggled {

        //     .menu-items-wrapper {
        //         top: 47px;
        //     }
        //   }

    }

    .header_wrapper {
        padding: 13px 30px;
        .account_wrapper svg {
            height: 20px;

        }
    }

    // .scrollwrapper {
    // padding-top: 46px;
    // }
}