.react-select-filter__menu {
  position: relative !important;
}

.product-filter {

.filter-button {
  padding-left: 32px !important;
  padding-right: 32px !important;
  svg {
        margin-right: 0px;
        width: auto !important;
        height: auto !important;
      
  }
}

.info-section {
  background-color: black;
  border-radius: 8px;
  padding: 18px;
  align-self: stretch;

}



}
.info-grey {
  background-color: $lab_grey_0;
  border-radius: 8px;
  padding: 18px;
  align-self: stretch;
svg {
    path {
      stroke: $c_accent_4;
    }
  }
}