.txtsm {
  @extend %t_body_xs
}

.redcircle {
  background: $c_accent;
  	width: 40px;
	height: 40px;
	border-radius: 50%;

	color: white;
	text-align: center;
	line-height: 40px;
	font-weight: 500;
	margin: 0 auto;
  &.disabled {
    color: $c_lab_grey_1;
  }
}
.shoppersignup {
textarea {
  height: 186px;
  width: 100%;
  padding: 10px 0px;
font-size: 20px;
// font-size: 24px;
  font-family: $f_body !important;
 @extend %h4;

}
}
.stripe-information {
   @extend %t_body_xs;
   line-height: 3em;
  // line-height: $r_28;
  a {
     line-height: 2.2em;
  }
  svg {
    width: 16px;
    height: 16px;

  }
}

