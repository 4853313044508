.bulk-size-num {
	width: 45px;
	text-align: center;
}

bulksizes {
	svg.icon-auto-width {
		padding: 5px;
	}
}

.size-qty-outer {
	position: relative;
}

.size-qty-wrapper {
	.field-group:first-child {

		// min-width: 186px;
		.label-wrap {
			min-width: 83px;
		}
	}

	.qty {
		@extend %blackline;
		min-width: 83px;

		input[type="number"] {
			width: calc(100% - 40px) !important;
			-moz-appearance: textfield;

			&:required {
				border-bottom: 0px solid #000000 !important;
			}
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

	}

	.field-group:last-child label {
		align-items: center !important;
	}

	.label-wrap {
		flex: 1;
	}
}

.add-row {
	cursor: pointer;
}

.addproductform_wrapper {
	.commission-amount {
		@include flex(row, center, start);
		position: relative;

		&::before {
			content: '$';
			position: relative;
			display: block;
		}
	}


	.gallery-wrap {
		@include flex(wrap, start, start);
		flex-flow: row wrap;

		.gallery-item {
			position: relative;
			width: 112px;
			/* margin-right: 5px; */
			/* max-width: 150px; */
			/* flex-basis: 32%; */
			/* min-height: 33%; */
			/* box-sizing: content-box; */
			// border: 1px solid #e5e5e5;
			/* border-style: inset; */
			// margin-top: 2px;
			// margin-left: 0px;
			/* margin-right: auto; */
			margin: 2px;

			.close {
				@include flex(row, center, center);
				position: absolute;
				top: 0;
				right: 0;
				background: white;
				height: 25px;
				width: 25px;
				text-align: center;

				svg {
					width: 15px;
				}
			}

			img {
				width: 100%;
			}
		}

		&:after {
			content: "";
			flex: auto;
		}
	}

	.submit-wrap {
		button {
			// border-width: 3px !important;
			// padding: 10px 20px !important;
			min-width: 218px;
		}
	}
}

.grad {}

.card-preview-start {
	@include flex(row, center, center);
	padding: 80px 50px;
	text-align: center;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	font-family: "Barlow Condensed";
}

.card-preview-wrapper {
	// transform-origin: top left;
	// transform: scale(0.995);
}

.card-preview-wrapper2 {
	img {
		width: 100%;
	}
}

.card-preview {
	@include flex(col, center, between);
	padding: 55px 0px 15px;
	position: relative;
	margin: 0 auto;
	// width: 412px;
	// height: 667px;
	background-color: white;
	text-align: center;
	width: 100%;
	min-height: 626px;
	box-sizing: border-box;
	position: relative;
	// background-size: cover !important;

	h3 {
		font-weight: 600 !important;
		line-height: 40px;
		text-transform: uppercase !important;
		font-size: 40px !important;
		letter-spacing: 0.03em;
	}

	h6 {
		margin-top: 4px;
		font-weight: 500 !important;
		font-size: 18px !important;
		line-height: 22px !important;
		text-transform: uppercase;
	}

	.logo-tag {
		width: 38px;
		height: 33px;
	}

	&.card-preview-fixed {
		// position: absolute;
		// top: 0;
		// left: 0;
		width: 100%;
		height: 100%;
		max-height: 736px;
		max-width: 414px;
		//  padding: 0px 0 !important;
		box-sizing: border-box;
		z-index: 9;
		transform-origin: top left;
		// transform: scale(1.5);

		// &::before {
		//     content: '';
		//     display: block;
		//     background-color: white;
		//     position: absolute;
		//     top: 0;
		//     left: 0;
		//     right: 0;
		//     width: 580px;
		//     height: 1120px;
		//     z-index: 200;
		// }

		.body-xs {
			// font-size: 32px !important;
			// line-height: 1.5em !important;
		}
	}

	.wideprovider {
		// position: relative;
		padding-bottom: 5px;
		padding-top: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;

	}

	.logo-tag {
		// position: absolute;
		// top: 80%;
		right: 0;
		width: 32px;
		height: 32px;
		z-index: 1;
		// bottom: 5px;//////////////
	}

	.card-text-bottom {
		h1 {
			letter-spacing: 0.03em;
			font-weight: 600;
			font-size: 30px !important;
			line-height: 30px;
			// height: 33px;//////////////
		}
	}

	.card-sizes {
		@include flex(wrap, center, center);
		font-size: 19px;
		line-height: 22px;
		text-align: center;

		.sizes-title {
			margin-right: 10px;
		}

		&.white {
			color: white;
		}

		&.black {
			color: black;
		}

		h6,
		span {
			@include flex(row, center, center);
			position: relative;

			&::before {
				content: '|';
				display: block;
				margin: 0 5px;
				color: $c_accent_4;
			}

			&:nth-of-type(1) {
				&::before {
					display: none;
				}
			}
		}

		.title {
			margin-right: 10px;
		}
	}

	&>* {
		position: relative;
		z-index: 1;
		width: 100%;
	}

	.card-image-preview {
		position: absolute;
		/* height: 100%; */
		left: 0px;
		width: 100%;
		z-index: 0;
		/* margin-top: 25%; */
		/* padding: 55px 0px 15px; */
		/* top: 0px; */
		/* bottom: 0px; */
		margin: 0 auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

	}

	.card-overlay-top {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 165px;
		z-index: 0;
		// background-image: linear-gradient(-180deg, rgba(255,255,255,0.90) 0%, rgba(255,255,255,0.00) 100%);
		// background: linear-gradient(
		// 	180deg,
		// 	rgba(0, 0, 0, 0.554688) 0%,
		// 	rgba(0, 0, 0, 0.408788) 37.09%,
		// 	rgba(0, 0, 0, 0.289407) 58.06%,
		// 	rgba(0, 0, 0, 0.0001) 100%
		// );
		background: linear-gradient(180deg,
				rgba(0, 0, 0, 0.554688) 0%,
				rgba(0, 0, 0, 0.554688) 0%,
				rgba(0, 0, 0, 0.554688) 0%,
				rgba(0, 0, 0, 0.551799) 0.01%,
				rgba(0, 0, 0, 0.543233) 0.02%,
				rgba(0, 0, 0, 0.534858) 0.03%,
				rgba(0, 0, 0, 0.529452) 0.04%,
				rgba(0, 0, 0, 0.526909) 0.05%,
				rgba(0, 0, 0, 0.524431) 0.06%,
				rgba(0, 0, 0, 0.0173423) 96.88%,
				rgba(0, 0, 0, 0.00297372) 99.99%,
				rgba(0, 0, 0, 0.0001) 100%,
				rgba(0, 0, 0, 0.0001) 100%);

		&.gwhite {
			background: linear-gradient(180deg, #ffffffc9, #ffffff82, #ffffff00);
		}
	}

	.card-overlay-bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 153px;
		z-index: 0;
		transform: rotate(-180deg);
		// background: linear-gradient(
		// 	180deg,
		// 	rgba(0, 0, 0, 0.554688) 0%,
		// 	rgba(0, 0, 0, 0.408788) 37.09%,
		// 	rgba(0, 0, 0, 0.289407) 58.06%,
		// 	rgba(0, 0, 0, 0.0001) 100%
		// );
		background: linear-gradient(180deg,
				rgba(0, 0, 0, 0.554688) 0%,
				rgba(0, 0, 0, 0.554688) 0%,
				rgba(0, 0, 0, 0.554688) 0%,
				rgba(0, 0, 0, 0.551799) 0.01%,
				rgba(0, 0, 0, 0.543233) 0.02%,
				rgba(0, 0, 0, 0.534858) 0.03%,
				rgba(0, 0, 0, 0.529452) 0.04%,
				rgba(0, 0, 0, 0.526909) 0.05%,
				rgba(0, 0, 0, 0.524431) 0.06%,
				rgba(0, 0, 0, 0.0173423) 96.88%,
				rgba(0, 0, 0, 0.00297372) 99.99%,
				rgba(0, 0, 0, 0.0001) 100%,
				rgba(0, 0, 0, 0.0001) 100%);
		transform: rotate(-180deg);

		&.gwhite {

			background: linear-gradient(180deg, #ffffffc9, #ffffff82, #ffffff00);
		}
	}
}

.bwwhite {

	span,
	h6 {
		&::before {
			color: #dadada !important;
		}
	}
}

.bwblack {

	span,
	h6 {
		&::before {
			color: #4a4a4a !important;
		}
	}
}

.commission-wrap {
	.commholder {
		justify-content: flex-end;
	}
}

.right-input-icon {

	/* border-radius: 25px; */
	/* min-width: 110px; */
	display: flex;
	align-items: center;
	justify-items: center;
	justify-content: flex-end;
	font-size: 15px;
	/* height: 35px; */
	margin-top: auto;
	margin-bottom: auto;
	/* line-height: 35px; */
	border-bottom: none !important;
	width: auto;
	text-align: right;
}

.field-wrap.subtotal input:before {
	content: "$";
	display: block;
	/* 	position: absolute; */
	/* width: 20px; */
	height: 100%;
	z-index: 1;
	box-sizing: border-box;
	border: 1px solid black;

	padding: 25px;
}