.trackerrow {
  padding: 16px 0px;
  border-top: 1px solid $c_lab_grey_1;
  &:first-child {
      border-top: none;
    }
}

.saitem {
  @extend %t_body_sm;
  // padding: 10px 0px;

  // .accordion-item__icon {
  //   width: 0;
  //   height: 0;
  //   border-left: 1px solid black;
  //   transform: rotate(-45deg);
  //   border-bottom: 1px solid black;
  //   align-self: center;
  //   background: none !important;
  //   transition: transform 0.3s ease-in-out;
  //   padding: 6px;
  //   box-sizing: content-box;
  //   line-height: 0em;
  //   font-size: 12px;
  //   border-spacing: 1px;
  //   margin-right: 3px;
  //   margin-bottom: 0px;
  // }


  // &.accordion-item--opened .accordion-item__icon {
  //   transform: rotate(135deg);
  // }

  // .imgtoggle {
  //   margin-right: 23px;
  // }

  // .justify-between>div {
  //   flex: 0 1 50%;
  // }

  .stracker {
    // margin-top: 44px;
    // // margin-bottom: 48px;
    // min-height: 200px;

    .labelbtns {
      flex-wrap: wrap;
      grid-gap: 22px;
      gap: 22px;

      svg {
        width: 60px;
        height: 85px;
      }
    }

    .row-group {
      display: flex;
      flex-wrap: nowrap;
      padding: 12px 0px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $c_lab_grey_1;

      &.inner {
        display: flex;
        width: 100%;
        border-bottom: none;
        padding: 0px;
      }


      .label-wrap {
        max-width: 89px;
        width: 100%;
        margin-right: 14px !important;
        color: $c_accent_3;
        text-align: right;
        margin-bottom: auto;
        justify-content: flex-end;
        display: flex;
        text-align: right;
        display: flex;

        svg {
          width: 16px;
          height: 16px;
          opacity: .35;
        }
      }

      .label-wrap-left {
        max-width: 94px;
        width: 100%;
        padding-right: 10px !important;
        color: $c_accent_3;
        text-align: right;
        margin-bottom: auto;
        justify-content: flex-start;
        text-align: left;
        display: flex;
        box-sizing: border-box;

        &.icon {
          margin-top: auto;
          justify-content: flex-end;
          padding-right: 15px !important;
          align-items: center;
          height: auto;

          svg {
            width: 16px;
            height: 16px;
            // opacity: .35;
          }
        }
      }


      .field-wrap {
        width: 100%;
      }
    }

  }

  .accordion-item__icon {
    align-self: center;
  }

}

.filter-toggler {
  position: absolute;
  top: 10px;
  right: 0px;
  cursor: pointer;

  .close-modal {
    display: none;
  }

  &.shown {

    color: $c_accent !important;

    svg.close-modal {
      display: flex;
      margin-top: 4px;
      margin-right: 8px;
      width: 18px;
      height: 18px;


      align-self: flex-start;

      g {
        stroke: $c_accent !important;
      }

    }
  }
}

.formcontain {
  align-self: auto;
  margin-top: 0px;
  margin-bottom: 48px;
  flex: 1 1 30%;
  max-width: 356px;
  margin: 0 auto;
}

.filterToggle {


  .filters {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    border-bottom: 1px solid $c_lab_grey_1;
    flex-wrap: nowrap;
    justify-content: space-between;
    // width: 268px;
    flex-grow: 0;
    margin: 0 auto;

    .greendot {
    width: 6px;
      height: 6px;
      background: #75B857;
      /* flex: none; */
      /* order: 1; */
      /* flex-grow: 0; */
      position: relative;
      margin-top: auto;
      margin-bottom: 10px;
      margin-right: auto;
      margin-left: 1px;
      border-radius: 6px;
      display: inline-block;
    }


    .active {
      @extend %filter;
    }

    &.search {
      .search {
        @extend %filter;
      }
    }

    &.date {
      .date {
        @extend %filter;
      }
    }

    &.csv {
      .csv {
        @extend %filter;
      }
    }
  }

  .funnel-label {
    font-family: $f_h1;
    cursor: pointer;
    padding-bottom: 8px;
    /* margin: 0 2px; */
    border-bottom: 1px solid $c_lab_grey_1;
    /* border-bottom-color: grey; */
    font-weight: 100;
    flex: 2 1;
    text-align: center;
    // font-family: barlow condensed;
    font-weight: 400;
    width: auto;
    flex-shrink: 1;
    flex-wrap: nowrap;
    flex-grow: 0;
    white-space: nowrap;
    margin-bottom: -1px;
    text-transform: uppercase;
    color: $c_accent_3;

  }

  &.payout-tab {

    .funnel-label {

      width: 100%;
      flex: 1 100%;

    }
  }

  .filter-field {
    // margin-top: 32px;
    // margin-bottom: 64px;
    width: 100%;
    justify-content: space-between;
    /* margin: 0px; */
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    // height: 42px;
    align-items: center;
    position: relative;
  }
}

.searchbox {
  border: 1px solid white;
  border-radius: 4px;

  svg {
    height: 100%;
    cursor: pointer;
    position: absolute;
    right: 12px;
    z-index: 2;
    // bottom: 20px;
    line-height: 1em;
    // top: 0px;
    // bottom: 0px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    path {
      stroke: $c_accent_3;
    }

    &.searchx {
      right: 60px;

      path {
        stroke: $c_primary;
      }
    }
  }


  &:focus-within {
    border: 1px solid $c_accent_4;

    svg {
      path {
        stroke: $c_primary;
      }
    }
  }



  input.searchfield {
    @extend %h4;
    background-color: $c_bg;
    width: 100%;
    // margin-right: 14px;
    // height: 100%;
    height: 44px;
    padding: 0px 10px;


  }
}

.greentext {
  color: $c_toggle !important;
}

.tablesalestracker {
  .tablecontainer {
    overflow: auto;
  }

  .btn {
    border-width: 2px;
  }


  select {
    font-size: 20px;
    line-height: 22px;
    font-family: $f_h1;
    text-align: center;
    border: none !important;
    background: transparent;
    padding: 0px 4px;
  }

  // .checkboxtable {}

  .expcheck {
    position: absolute;
    z-index: 8;
    background: white;
    /* padding: 0px 9px 9px 0px; */
    width: 16px;
    height: 16px;
    border: 4px solid white;
    overflow: hidden;
    border-left: none;
    border-top: none;
  }

  input[type="checkbox"] {
    width: 0px;
    height: 0px;
    margin-left: -20px;
  }

  input[type=checkbox]:checked:before {
    background: $c_toggle;
    content: "\2713";
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    font-size: 14px;
    line-height: 0.9em;
    padding-left: 1px;
    color: white;
    border-color: $c_toggle;
    color: white;
    font-weight: bold;
  }

  input[type=checkbox]:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 1px solid black;
    background: #fff;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
  }

  .imgblocker {
    position: absolute;
    width: 88px;
    height: 88px;
    z-index: 1;
  }

  .i-plus {
    cursor: pointer;
    font-style: normal;
    line-height: 15px !important;
    /* display: block !important; */
    height: 17px;
    justify-content: center !important;
    /* margin-top: auto; */
    /* margin-bottom: auto; */
    /* border: 1px solid #727272 !important; */
    width: 17px;
    /* font-weight: 200 !important; */
    /* font-family: sans-serif !important; */
    /* border-radius: 50%; */
    /* display: flex; */
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
    /* font-family: "Barlow Condensed", sans-serif; */
    text-align: center;
    position: absolute;
    z-index: 7;
    background: rgba(255, 255, 255, 0.60);
  }

  .nice-dates {
    z-index: 9;
    max-width: 400px;
    position: relative;
    width: 100%;

    .datepinput {
      display: flex;
    }

    .date-range {
      // @include flex(null, null, center);
      justify-content: space-evenly;
      display: flex;
    }

    input.input {
      width: 70px;
      font-size: 20px;
      line-height: 22px;
      font-family: $f_h1;
      text-align: center;
      border: none !important;
      background: transparent;
      padding: 0px 4px;
    }
  }

  .imgtoggle {
    min-width: 88px;
    height: 88px;
    position: relative;
    overflow: hidden;
    margin-right: 14px;

    img.thumb {
      @extend %tablethumb;
    }
  }

  .lgImg {
    .imgtoggle {
      min-width: 116px;
      height: 116px;
    }
  }

  .notifications {

    .imgtoggle {
      min-width: 95px;
      height: 124px;
    }
  }


}

.badge-red {
  @extend %t_body_sm_cond;
  color: white;
  padding: 0px 6px 2px;
  background: $c_accent;
  border-radius: 4px;
  display: inline-flex;
  margin-bottom: 10px;
  margin-right: auto;
}

.bar-red {
  @extend %h5;
  @include flex(row, center, center);
  background: $c_accent;
  flex: 1;
  width: 100;
  height: 50px;
  color: $c_secondary;
  margin-bottom: 32px;
  margin-top: -16px;
}



//  &::before {

//         content: "\00B7";
//         font-size: 25px;
//         color: $c_accent !important;
//         margin: 0 10px;
//     }