// .edit-form-main {
//     &.loading {
//         .overlay {
//             @include flex(row, center, center);
//             position: fixed;
//             top: 0;
//             left: 0;
//             right: 0;
//             bottom: 0;
//             z-index: 1;

//             svg {
//                 width: 100px;
//                 height: 100px;
//             }
//         }
//     }
// }

.edit_form_wrapper {

    .product-images {
        @include flex(wrap, start, around);

        .product-image {
            position: relative;
            margin-bottom: 10px;
        }
    }

    // .iseditpage-true>div:first-child {
    //     display: none !important;
    // }

    form,
    .form {
        .field-group {
            .field-wrap {

                &.submit-wrap {

                    #updateAlert {
                        visibility: hidden;
                        margin-top: 20px;

                        &.showAlert {
                            visibility: visible !important;
                            color: #f25f3a;
                        }
                    }

                    &.submit-wrap-edit {
                        @include flex(col, center, center);

                        a {
                            @extend %t_body_xs;
                            margin-top: 10px;
                        }
                    }
                }

            }
        }
    }

    #copyAlert {
        @extend %t_body_xs;
        color: $c_accent;
        display: none;

        &.showAlert {
            display: block;
        }
    }
}