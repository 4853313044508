.pricing-plans {
  display: flex;
    gap: 32px;
    flex-wrap: wrap;

  .plan {
  
    border-color: $c_lab_grey_1;
    border-radius: 12px !important;
    border-style: solid;
    overflow: clip;
    border-width: 2px;
    cursor: pointer;
  flex-basis: 361px;

    &>div {
      padding: 32px 20px;
    }

    &.active {
      border-color: $c_accent;
      background-color: $c_labred;
    }

    .notify-padding {
      padding: 0px 20px;
      height: 50px;
      border-radius: 0px;
      position: relative;
    }

    .price-crossedout {
      text-decoration: line-through;
      margin-right: 10px;
    }
  }
}