

*:focus {
    outline: 0 !important;
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

// GENERAL STYLES
.site-content {
    box-sizing: border-box;
    padding: 40px 30px 26px;
    margin-top: 57px;
    min-height: calc(100vh - 125px);
    width: 100%;
    overflow: hidden;
}

.site-contain {
    @extend %site-contain;
}

body {
    @extend %t_body;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %heading;
}

.h42 {
    @extend %h42;
}

.h40 {
    @extend %h40;
}

h1,
.h1 {
    @extend %h1;
    &.cond-header {
            font-style: normal;
            font-weight: 400;
            font-size: 52px;
            line-height: 40px;
            letter-spacing: 2px;

    }
}
.bolder {
    font-weight:500 !important
}

h2,
.h2 {
    @extend %h2;
}

h3,
.h3 {
    @extend %h3;
}

h4,
.h4 {
    @extend %h4;
}

h5,
.h5 {
    @extend %h5;
}

h6,
.h6 {
    @extend %h6;
}

.title {
    @extend %title_sm;
}

.title-cond {
    @extend %title_sm_1;
    color: $c_accent_4;
}

.title-header {
    font-family: $f_body;
    font-weight: 500;
    font-size: 15px;
    color: $c_accent_4;
}

.body {
    @extend %t_body;
}

.body-cond {
    @extend %t_body_cond;
}

.body-xs {
    @extend %t_body_xs;
}

.body-sm {
    @extend %t_body_sm;
}

.body-sm-bold {
    @extend %t_body_sm_cond;
}

.body-lg {
    @extend %t_body_lg;
}

.body-lg-cond {
    @extend %t_body_lg_cond;
}

.title_sm_1 {
    @extend %title_sm_1;
}

.title_sm_2 {
    @extend %title_sm_2;
}

.title_sm_3 {
    @extend %title_sm_3;
}

.h5-cond-med {
    font-family: $f_h1;
    font-weight: 400;
    font-size: 20px;
}

.fcond,
small {
    font-family: $f_h1;
}

a {
    @extend %link;

    &.alt-link {
        @extend %alt_link;
    }

    &:focus {
        outline: none;
    }
}

.link {
    @extend %link;
    margin-right: 20px;
    font-family: $f_btn !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.post,
.page {
    margin: 0;
}

#page {
    position: relative;
}

p {
    @extend %t_body;
    margin-top: 1px;
    margin-bottom: 20px;
}

p.m0 {
    margin-bottom: 0px;
}

.percent {
    padding: 0 5px;
}

.pointer {
    cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    font-size: 24px;
    line-height: 25px;
    border: none;
    border-radius: 0 !important;
    padding: 5px 10px;

    &:focus {
        outline: none !important;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

 input.Input::placeholder,
 input::placeholder,
input::-webkit-input-placeholder,
select::placeholder,
textarea::placeholder,
textarea::-webkit-input-placeholder {
    color: #C3C3C3;
}

input::-ms-clear,
input::-ms-reveal,
input::-webkit-contacts-auto-fill-button,
input::-webkit-caps-lock-indicator,
input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.steals .form {
    font-size: 18px;
    font-family: "Barlow Condensed";
}

.selectField {
    @extend %h4;
    flex-basis: 100%;
    line-height: 1.3em;
}

.popup-more {
    position: absolute;
    display: inline-block;
    right: 40px;
    top: -5px;

    &.collapse {
        display: none;
    }
}

.select-emu-item {
    position: relative;
    background-color: $c_lab_grey_1;
    min-width: 220px;
    overflow: auto;
    box-shadow: 0px 0 14px 0px rgb(0 0 0 / 8%);
    z-index: 1;
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box;
}

.lists ul,
.lists ol {
    padding-inline-start: 16px;
    margin-block-start: 0px;

    li {
        color: black !important;
        padding-left: 5px;
        margin-bottom: 20px;

        &::marker {
            content: "\2715";
            font-size: 12px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            position: absolute;
            top: 0;
            left: 0;
            font-weight: 500;
        }
    }

    li.green {
        color: #75B857 !important;

        &::marker {
            content: "\2713";
        }
    }
}
.lists-sm ul,
.lists-sm ol {
    padding-inline-start: 16px;
    margin-block-start: 0px;
    margin-block-end: 0px;

    li {
        color: black !important;
        padding-left: 0px;
        margin-bottom: 10px;

        &::marker {
            // content: "\2715";
            font-size: 12px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            position: absolute;
            top: -2px;
            left: 0;
            font-weight: 500;
        }
    }

    li.green {
        color: #75B857 !important;

        &::marker {
            content: "\2713";
        }
    }
}


.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.cprimary {
    color: $c_primary;

    &:hover {
        color: $c_primary;
    }
}

.red-text {
    color: $c_accent !important;
    &.bold {
        font-weight:  500 !important;
    }
}

.white-text {
    color: white !important;
}

.error-message {
    color: $c_accent !important;
    text-align: center;
}

.grey-border {
    border-color: $c_lab_grey_1 !important;
}

.lightgrey-border {
    border-color: $lab_grey_0 !important;
}
.black-border {
    border-color: $c_primary !important;
}

.blackline {
    @extend %blackline;
}

.greyline {
    @extend %greyline;
    width: 100%;
}

.darkgreyline {
    @extend %darkgreyline;
    width: 100%;
}

.text-labgrey3,
.greytext {
    color: $c_accent_4;
}

.greentext {
    color: $c_green;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.lightgrey {
    color: $c_accent_3;

    a {
        color: $c_accent_4 !important;
    }
}

.blacklink {
    color: black;
}

.bg-red {
    background: $c_accent;
}

.smallgreytext,
.xsm-sc-reg {
    @include flex(row, center, center);
    width: 100%;
    line-height: 20px;
    color: $c_accent_4;
    @extend %t_body_xs;
    align-self: auto;
    padding-top: 2px;
    padding-bottom: 28px;
    text-align: left;
    justify-content: flex-start !important;

    &.centered {
        border: none !important;
        padding-bottom: 0px;
        text-align: center;
        line-height: 24px;
    }
}

.active-green,
.greenbg {
    @extend %active-green;

    label {
        color: $c_green !important;
    }
}

.redbg {
    background-color: $c_labred !important;

    label {
        color: $c_red !important;
    }
}

.icon-right {
    padding-right: 8px;
}

.icon-left {
    margin-right: 12px;
}

.pad-right-24 {
    padding-right: 24px !important;
}
// input:invalid {

// }

.reset_confirm {
    .form-link {
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
        line-height: 28px;
    }
}

.hy-inval {
    display: inline;
    position: relative;
}

.adjust-center {
    @extend %adjust-center;
}
.adjust-sides {
    flex-basis: auto;
}

.signature {
    text-align: center;
    font-family: $f_title;
    font-size: 22px;
    margin: 20px 0;
}

.script-font {
    font-family: $f_title;
}

.lazy {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.lazy-contain {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.line-sep {
    position: relative;
    width: 67px;
    height: 2px;
    background: $c_accent;
    // margin: 17px 0 10px;
    margin-top: 47px;
    margin-bottom: 20px;
}

.dot-sep {
    position: relative;

    &::before {
        content: "\00B7";
        font-size: 25px;
        color: $c_primary;
        margin: 0 10px;
    }
}

.show {
    display: block !important;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}



.social-icon {
    @include flex(row, center, center);
    margin: 0;
}

.block-full {
    width: $full;
}

.block-half {
    width: $half;
}

.block-fourth {
    width: $fourth;
}

.block-third {
    width: $third;
}

.block-fifth {
    width: $fifth;
}




.pad-section {
    padding: $pad 0;
    margin-top: $pad;
    margin-bottom: $pad;
}
.pad-20{
     margin-top: $pad_small;
    margin-bottom:  $pad_small;
}
.pad-20-30 {
    padding: 20px 30px;
    margin-top: -15px;
}

.container {
    @extend %container;
}

.container-xs {
    @extend %site-contain;
    max-width: 320px;
}

.container-sm {
    @extend %site-contain;
    max-width: 690px;
}

.container-md {
    @extend %container;
    max-width: 900px;
}

.container-lg {
    @extend %container;
    max-width: 1060px;
}

.container-xl {
    @extend %container;
    max-width: 1200px;
}

#content {
    position: relative;
    padding: 0;

    .site-branding {
        padding: 65px 0;

        #logo {
            display: block;
            margin: 0 auto;
            max-width: 380px;
        }
    }
}

.flex-wrap {
    @include flex(wrap);
}

.flex-row {
    @include flex(row);
}

.flex-row-rev {
    @include flex(row-rev);
}

.flex-col {
    @include flex(col);
}

.flex-col-rev {
    @include flex(col-rev);
}

.align-start {
    @include flex(null, start);
}

.align-center {
    @include flex(null, center);
}

.align-end {
    @include flex(null, end);
}

.justify-start {
    @include flex(null, null, start);
}

.justify-end {
    @include flex(null, null, end);
}

.justify-around {
    @include flex(null, null, around);
}

.justify-center {
    @include flex(null, null, center);
}

.justify-between {
    @include flex(null, null, between);
}

.flex-gap {
    flex-wrap: wrap;
    gap: 12px;
}

.flex-grow {
    flex-grow: 1;
}

.flex-content {
    flex-basis: content;
    flex-shrink: 0;
}

.fullflex {
    flex: 1;
}

.halfflex {
    flex: 0 1 50%;
}

.w-100 {
    width: 100%;
}

.icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.alm-btn-wrap .alm-load-more-btn {
    height: auto !important;
    background: unset !important;
    color: black !important;
    font-size: 24px !important;
    text-transform: capitalize !important;
    letter-spacing: 3px !important;
    font-weight: 100 !important;
    margin-bottom: 0px !important;
    margin-top: 50px !important;
    font-family: $f_title !important;

    span {
        @extend %ease;
    }

    &:hover {
        span {
            margin-left: 10px;
        }
    }
}

.slick-slider {
    position: relative;

    .slick-arrow {
        position: absolute;
        top: 35%;
        font-size: 20px;
        color: $c_primary;

        &.slick-prev {
            left: -1%;
        }

        &.slick-next {
            right: -1%;
        }

        &:hover {
            cursor: pointer;
            color: $c_secondary;
        }
    }

    .slick-dots {
        padding: 0;
        margin: 0;
        list-style: none !important;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        bottom: -30px;

        li {
            width: 12px !important;

            &.slick-active {
                button {
                    padding: 0 !important;
                    background: $c_accent_4 !important;

                    &:hover {
                        background: $c_accent_4 !important;
                    }
                }
            }

            button {
                position: relative !important;
                color: transparent !important;
                width: 6px !important;
                height: 6px !important;
                background: #d6d6d6 !important;
                border-radius: 100% !important;
                padding: 0 !important;
                border-width: 0px !important;

                &:hover {
                    background: #e7e7e7 !important;

                    &::after {
                        background: unset !important;
                    }
                }

                &::before {
                    display: none;
                }

                &::after {
                    background: unset !important;
                }

                &::focus {
                    outline: none !important;
                }
            }
        }
    }

    @include respond-to(xxlarge, max) {}

    @include respond-to(xlarge, max) {}

    @include respond-to(large, max) {}

    @include respond-to(medium, max) {}

    @include respond-to(small, max) {}
}

.animate-right {
    position: relative;

    .icon {
        margin-left: 5px;
    }

    &:hover {
        .icon {
            margin-left: 10px;
        }
    }
}

.text-descriptor {

    @extend %t_body_lg;
      font-family: $f_desc;
    font-style: italic;
}

.msg-notice {
    background: #f060401a;

    margin: 50px -30px;
    padding: 30px 23%;
    font-size: 17px;
    text-align: center;
}

.r-xs {
    @extend %r-xs;
}

.s-xs {
    @extend %s-xs;
}


.r-sm {
    @extend %r-sm;
}

.r-sm-x {
    @extend %r-sm-x;
}

.s-sm {
    @extend %s-sm;
}

.s-sm-x {
    @extend %s-sm-x;
}

.r-md {
    @extend %r-md;
}

.s-md {
    min-height: 70px;
}

.h-4 {
    min-height: 4px;
}

.h-8 {
    min-height: 8px;
}

.h-12 {
    min-height: 12px;
}

.h-16 {
    min-height: 16px;
}

.h-24 {
    min-height: 24px;
}

.h-32 {
    min-height: 32px;
}

.h-30 {
    min-height: 30px;
}

.pad-30 {
    padding: 30px;
}

.h-40 {
    min-height: 40px;
}

.h-48 {
    min-height: 48px;
}


// .h-48 {
//     min-height: $s-50;
// }

.h-60 {
    min-height: 60px;
}

.h-64 {
    min-height: 64px;
}

.h-80 {
    min-height: 80px;
}

.h-100 {
    min-height: 80px;
}

.m-100 {
    margin-bottom: $s_100;
}

.r-lg {
    @extend %r-lg;
}

.s-lg {
    @extend %s-lg;
}

.r-xl>* {
    // @extend %r-xl;
    padding-top: 26px;
    /* padding-bottom: 10px; */
    min-height: 114px;
}

.s-xl {
    @extend %s-xl;
}
.text-right {
    text-align: right;
}

.grecaptcha-badge {
    visibility: hidden;
}
.z10 {
    z-index: 101 !important;
}
.hidden {
    display: none;
}

.required {
    border-bottom: 1px solid $c_primary !important;
    .selectField {
        border-bottom: none;
    }
}

.hf-warning {
    position: absolute;
    color: red;
    // z-index: 2;
    // top: 0px; 
    // padding-top: 2px;
    font-size: 12px;
    line-height: 17px;
    border-bottom: 1px solid red !important;
    width: 100%;
    height: 100%;
    background: #ffefef;
    // padding-left: 5px;
    box-sizing: content-box;
    pointer-events: none;
    z-index: 0;
    left: 0px;
    line-height: 1.8em;

    &.cnt-field {
        z-index: 0
    }

    &::before {
        content: " ";
        padding-right: 5px
    }
}

.noborder {
    border: none !important;
}

.nopadding {
    padding: 0px !important;
}


form,
.form {
    max-width: 354px;
    margin: 0 auto;
    border-top: 1px solid $c_lab_grey_1;

    .form-section-header {
        @extend %h3;
        width: 100%;
        padding: 16px 0;
        // margin-top: 45px;
        @extend %greyline;
    }

    .add-row {
        text-transform: uppercase;
        color: $c_accent_4;
        // padding: 16px 0;
    }

    .form-link {
        text-align: center;
    }

    .terms-link {
        color: $c_accent_3;
        text-align: center;
        @extend %title_sm_3;

        a {
            @extend %alt_link;
            @extend %title_sm_3;
        }
    }

    .forgot-wrap {
        @extend %t_body_xs;
        @include flex(row, center, center);
        font-size: 16px;
        line-height: 18px;

        a {
            color: $c_accent_3;
        }
    }

    .field-group {
        @include flex(row, null, space-between);
        // width: 100%;
        flex-grow: 1;

        .label-wrap {
            @include flex(col, start, center);
            // flex: 1;
            flex: 0 1 83px;
            margin: 0 !important;
            @extend %greyline;
            justify-items: flex-start;
            line-height: 1em; // added !!!!

            .details {
                margin-bottom: auto;
            }

            label,
            .label-element {
                @include flex(col, start, center);
                @extend %title_sm_1;
                text-transform: uppercase;
                color: $c_accent_4;
                width: 100%;
                line-height: 1em;
                // height: 100%;
                // @extend %greyline;
                // white-space: nowrap;
                // line-height: 1em;
            }

            &.fullflex {
                flex: 1;
            }

            &.halfflex {
                flex: 0 1 60%;
            }
        }

        textarea#details {
            @extend %h4;
            @include flex(row, center, start);
            width: 100%;
            padding: 16px 0 16px 10px;
            line-height: 1.2em;
            // z-index: 1;
            //line-height: 20px;

            // @extend %greyline;
            width: 100%;

            padding-top: 6px;
            padding-bottom: 0px;
            margin-top: -8px;
            padding-bottom: 7px;
            line-height: 25px;
            @extend %greyline;

        }

        .field-wrap>div {
            @extend %greyline;
        }

        .field-wrap {
            position: relative;
            @include flex(row, null, stretch);
            flex: 3;
            // flex-grow: 1;
            margin: 0 !important;
            &.eightup {
            margin-top: -8px !important;
            }
            // @extend %greyline;
            input,
            textarea {
                @extend %greyline;

                &.required {
                    border-bottom: 1px solid #000000;
                }

                &.customtxtarea {

                    padding-top: 0px;
                    flex: 1;
                }
            }

            @include respond-to(medium, max) {
                flex: 2;
            }

            i.input-field-icon {
                    position: absolute;
                    right: 14px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    z-index: 100;
                }


            .remove-row {
                position: absolute;
                top: 27%;
                right: 0;
                width: 30px;
                height: 30px;
                z-index: 3;
            }

            .field-element-half {
                width: 50%;
                padding: 16px 0 16px 10px;
                @extend %greyline;

                &:required,
                &.required {
                    border-bottom: 1px solid $c_primary;
                }

                &:focus {
                    border-bottom: 2px solid $c_primary !important;
                }

                input {
                    width: auto;
                    margin-right: 5px;
                }
            }

            &.toggle-wrap {
                @extend %greyline;
                padding: 16px 0;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            input,
            // textarea,
            .field-element {
                @extend %h4;
                @include flex(row, center, start);
                width: 100%;
                padding: 16px 0 16px 10px;
                line-height: 1.2em;
                width: 100%;

                &.StripeElement {
                    z-index: 1
                }

                .__PrivateStripeElement {
                    width: 100%;
                }

                &.cnt-field {
                    z-index: 1;
                }

                &:required,
                &.required {
                    border-bottom: 1px solid $c_primary;
                }

                &.rederror {
                    border-color: red !important;
                }

                // &:focus {
                //     // border-bottom: 2px solid $c_primary !important;
                // }
                &::-webkit-validation-bubble-message {
                    display: none;
                }

                // &::before {
                //     content: '';
                //     display: block;
                //   }
            }

            input {
                z-index: 1
            }

            .bubble-slider {
                input[type="radio"] {
                    display: none !important;
                }
            }




            .radio-col {
                >div {
                    display: flex;
                    flex-direction: column;

                    label {
                        color: $c_accent_3;
                        font-size: 16px;
                        line-height: 1.5em;
                    }

                    input {
                        margin: 0 auto !important;
                    }
                }

            }


            &.submit-wrap {
                @include flex(row, center, center);
            }



            &:hover {
                cursor: pointer;
            }

            &.adddollar {
                position: relative;
                padding-left: 13px;
                // border-bottom: 1px solid $c_primary;

                &::before {
                    position: absolute;
                    top: 0;
                    content: "$";
                    left: 0px;
                    padding: 16px 0 16px 10px;
                    line-height: 1.6em;
                    font-family: "Barlow Condensed", sans-serif;
                    font-size: 24px;
                    font-weight: 400;
                }

                input {
                    border-bottom: none !important;
                }
            }
        }

        &.file-container {
            .field-wrap {
                flex: 1;
            }

            .field-wrap {
                .file-select {
                    @include flex(row, center, end);

                    input[type="file"] {
                        width: 0.1px;
                        height: 0.1px;
                        opacity: 0;
                        overflow: hidden;
                        position: absolute;
                        z-index: -1;

                        &+label {
                            cursor: pointer;
                            padding: 0;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        &.textarea-container {
            padding-top: 10px;

            .nf-field-label {
                @include flex(col, start, stretch);

                label {
                    @include flex(col, start, start);
                }
            }
        }
    }

    .stacked-radio {
        input[type="radio"] {

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;
            //    display: inline-block;
            background-color: white;
            border: 2px solid $c_primary !important;
            border-radius: 100% !important;
            width: 24px !important;
            height: 24px;
            cursor: pointer;
            margin-right: 7px;
            padding: 0 !important;
            outline: none;

            &:checked {
                &::before {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    width: 16px;
                    height: 16px;
                    background: $c_primary;
                    border-radius: 100%;
                }
            }
        }
    }

.checkBoxLabel {
  justify-content: flex-start;
  margin: 0 auto;
  flex-direction: column;
  flex: 0 1 83px;
  display: flex;
//   align-items: center;
  margin-top: 20px;
}
.CheckBoxText {
    flex: 2 1;
}
    [type="checkbox"] {
        border-radius: 6px;
        &:not(:checked),
        &:checked {
            position: absolute;
            left: -9999px;
            &+label {
                position: relative;
                padding-left: 2.5em;
                cursor: pointer;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 30px;
                    height: 30px;
                    box-sizing: border-box;
                    border: 2px solid $c_primary;
                    border-radius: 6px;
                    // border-radius: 4px;
                }

                &::after {
                    content: "\2713";
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                        Roboto, "Helvetica Neue", Arial, sans-serif,
                        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
                        "Noto Color Emoji";
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 34px;
                    line-height: 0.4;
                    transition: all 0.2s;
                }
            }
        }

        &:not(:checked) {
            &+label {
                &::after {
                    opacity: 0;
                    transform: scale(0);
                }
            }
        }

        &:checked {
            &+label {
                &::before {
                    background: $c_primary;
                }

                &::after {
                    opacity: 1;
                    transform: translate(0px, 8px) scale(0.8);
                    color: white;
                }
            }
        }
        &.round {
    
            &:not(:checked),
            &:checked {
                &+label {
                    // position: relative;
                    // padding-left: 2.5em;
                    // cursor: pointer;
    
                    &::before {
                        border: 2px solid $c_lab_grey_1;
                        background: $c_lab_grey_1;
                        border-radius: 36px;
                        margin-left: 15px;
                    }
                }
            }

            &:not(:checked) {
                &+label {
                    &::after {
                        opacity: 0;
                        transform: scale(0);
                        margin-left: 15px;
                    }
                }
            }
            &:checked {
                &+label {
                    &::before {
                        border: 2px solid $c_green;
                        background: $c_green;
                        margin-left: 15px;
                    }

                    &::after {
                        opacity: 1;
                        transform: translate(0px, 8px) scale(0.6);
                        color: white;
                        margin-left: 15px;
                    }
                }
            }
        }
    }





    &.no-width {
        max-width: 100% !important;
    }
    
}


.delete-row {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;

    &.greystroke path {
        stroke: $c_accent_3;
    }

    &.lg-icon {
        width: 45px;
        height: 45px;
    }

    &.icon-auto-width {
        width: initial !important;
        height: initial !important;
        max-width: 100%;
    }

    &.rotate-270 {
        transform: rotate(270deg);
    }

    &.rotate-180 {
        transform: rotate(180deg);
    }

    &.rotate-90 {
        transform: rotate(90deg);
    }
    &.big-svg-arrow {
            stroke: $c_accent_3;
            cursor: pointer;
            padding: 22px 20px;
    }
}


button,
input,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    box-shadow: unset;
    background: unset;
    border: unset;
    // border-radius: 0 !important;
    padding: unset;
    font-size: 0.9rem;
    cursor: pointer;

    &:focus {
        outline: none !important;
    }

    &:disabled {
        border-color: $c_lab_grey_1 !important;
        color: $c_accent_4 !important;

        &:focus,
        &:hover {
            border-color: $c_lab_grey_1;
            color: $c_lab_grey_1;
        }
    }
}

.content-area {
    padding: 50px 30px;
}

.page-content {
    margin-top: 0;
}

h1.page-title,
.page-title {
    font-family: $f_title;
    font-size: 45px;
    line-height: 50px;
}
.landing-img {
width: 100vw;
    max-width: 800px;
    margin-left: -30px;
    margin-right: -30px;
}
.page-header {

    // padding: 0 10px;
    @include respond-to(medium, max) {
        padding: 0 0px;
    }

    margin-bottom: 48px;
    // margin-bottom: 50px;
}

.page-header-admin {
    margin-bottom: 80px;
    // margin-top: 12px;
}

.react-toggle {
    margin-left: 10px;

    &:focus {
        outline: none !important;
    }

    & * {
        &:focus {
            outline: none !important;
        }
    }

    .react-toggle-thumb {
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }
}

.react-toggle--checked .react-toggle-track,
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $c_toggle;
}

.react-toggle--checked .react-toggle-thumb {
    border-color: $c_toggle;
}

.react-toggle--focus .react-toggle-thumb {
    outline: none !important;
    box-shadow: none !important;
}

.fullheight {
    min-height: 60vh;
}

.close-wrap {
    @include flex(row, center, center);
    background: $c_secondary;
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    text-align: center;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: -1px 1px 5px -1px #dedede;
}


.react-reveal {
    position: relative;
    z-index: 100;
}


.overlay {
    @include flex(row, center, center);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: hsla(0, 0%, 100%, .9);

    svg {
        width: 100px;
        height: 100px;
    }
}


.stripe-table {
    border-collapse: collapse;

    thead {
        @extend %greyline;
        height: 40px;
    }

    tr {
        @extend %greyline;
    }

    td {
        height: 50px;
        //   padding: 0.5em 0;
        text-align: center;

        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }

    th {

        font-weight: 500;
        padding: 0px;


    }

    th {
        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }
}

.font-60 {
    font-size: 30px;
    font-family:'Barlow Condensed';
    font-weight: 600;
    line-height: 60.01px;
    text-align: center;
    letter-spacing: 0.86px;
}

.profile-logo-placeholder {
    font-size: 94px;
    color: white;
    font-weight: 500;
    line-height: 132px;
    margin-top:-8px;
}

.shop-landing-logo-placeholder {
    font-size: 36px;
    color:white;
    font-weight: 500;
    margin-top: -3.5px;
}

.avita-imageholder {
    font-size: 48px;
    color:white;
    font-weight: 500;
    margin-top: -4px;
}

.header-imagepalceholder{
    width: 32px;
    height: 32px;
    text-align: center;
    background: linear-gradient(135deg,#F6ECD7, #CEB380);
    border: solid  #80808038 1px;
    border-radius: 50%;
    font-size: 20px;
    color: white;
}

.shopper-profile-review, 
.shopper-profile-rate, 
.total-items-sold,
.available-items-listed,
.seller-review-all,
.view-per-page,
.reviews-header  {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.see-all-steals, .see-all-reviews, .reviews-header-left {
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-link {
    color:black !important;
}

.dropdown-menu {
    border:none !important;
    border-radius: 0 ;
    margin-right: 40px !important;
    margin-top: -53px !important;
}

.dropdown-menu button {
    border:none !important;
    justify-content: flex-start !important;
    border-radius: 2px !important;
    padding: 5px !important;
    width:250px !important;
    text-transform: capitalize !important;
}

.dropdown-menu .option {
    background-color: none !important;
}

.dropdown-menu .active-option {
    background-color: #0000004a!important;
}

.option-icon {
    margin-top: -8px;
}

a {
    text-decoration: none !important;
}

.dropdown-item p {
    margin: 0 !important;
    font-size: 18px !important;
    color:black !important;
}

.view-per-page {
    margin:auto;
    width:250px;
    margin-top: 47px; 
    div {
        font-size: 16px;
    }
}

.view-per-page-wrapper {
    justify-content: center;
    align-items: center;
}

.name-review-name {
    // margin-left: 2px;
    line-height: 14px;
    margin-bottom: -5px;
    margin-top: 3px;
}

.name-review {
    margin-left:12px;
}

.seller-review-all{
    // h3 {
    // padding-top:27px;
    // }
    .required {
    border: none !important;
    max-width:220px;
    }
    .react-select__clear-indicator {
    display:none !important;
    }
}

.reviews-header-right {
    font-size: 12px !important;
    margin-top:-12px;
}

.reviews-header-left img {
    margin-top: -5px;
    border-radius: 16px;
}

.react-select__indicator svg {
    width:30px;
    height:30px;
}

.input-wrap-phone input {
    border-bottom: 1px solid #000000 !important;
}

.sales-associate .hf-warning {
    margin-top: -1px !important;
    z-index: 1 !important;
}

.seller-profile-instagram {
    font-size: "20px";
    font-weight: "400";
    line-height: "28px";
    font-family: "Barlow Condensed"
}

.header-profile-text {
    font-weight: 500;
}

.grey4 {
    color: #686868;
}

.login_wrapper .hf-warning {
    top:-1px !important;
}
.hf-wrapping-sales {
    top: -1px !important;
  //  z-index: 1;
}
.alert-orders {
    margin:0;
    margin-left:5px;
    color:red;
    font-size:16px;
    line-height: 20px;
    font-family: 'Barlow condensed';
}

.alert-orders-bottom {
    margin:0;
    margin-left:5px;
    color:#F05F40;
    font-size:16px;
    line-height: 20px;
    font-family: 'Barlow condensed';
}

.text-resolve {
    font-size:16px;
    line-height: 20px;
    font-family: 'Barlow condensed';
    color:white;
    background: #F05F40;
    width: 80px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}


.alert-orders-wrapper{
    display: flex;
    align-items:center;
    padding-left: 20px;
    height: 52px;
    background: rgba(240, 95, 64, 0.15);
    border-radius: 4px;
    margin: 0 -7px;
}
.alert-orders-wrapper-bottom{
    display: flex;
    align-items:center;
}
.alert-orders-wrapper-bottom svg {
    margin:0 !important;
    width: 16px !important;
    height:16px !important;
}

.order-instagram .label {
    margin-top: -5px;
    margin-right: 8px;
}

.text-underline {
    text-decoration: underline;
}


.coupon-wrapper {
    input[type="text"].couponinput {
        text-transform: uppercase;
    }
}