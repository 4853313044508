.money {

    @include flex(row, start, start);
    padding: 0 5px;
     padding-left:0px;

    .neg-sign {
            line-height: 2em;
            font-weight: bold;
    
        }
        
    .dollar-sign {
        font-size: 26px;
        line-height: 1.5em;
    }
    .dollar-amount {
        font-size: 52px;
        line-height: 1em;
        margin: 0 2px 0 3px;
    }
    .cents-amount {
        font-size: 26px;
        line-height: 1.5em;
    }

    &.small {
        .dollar-sign {
            font-size: 20px;
            line-height: 1em;
        }
        .dollar-amount {
            font-size: 20px;
            line-height: 1em;
            margin: 0;
        }
        .cents-amount {
            font-size: 20px;
            line-height: 1em;
        }
    }
    &.medium {
         span {
        font-size: 24px !important;
       line-height: 25px !important;
         }
    }
    &.xsmall {
        .dollar-sign {
            font-size: 16px;
            line-height: 1em;
        }
        .dollar-amount {
            font-size: 16px;
            line-height: 1em;
            margin: 0;
        }
        .cents-amount {
            font-size: 16px;
            line-height: 1em;
        }
    }
}