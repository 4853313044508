
.avitar {
    display: flex;
    align-items: center;
    justify-items: flex-start;
    text-align: left;
    justify-content: left;
    color: black;
    // margin-top: 10px;
    // margin-bottom: 10px;
    // a {
    //     color: black;
    // }

    span {
        font-size: 16px;
    }

    .imgholder {
        background: linear-gradient(135deg,#F6ECD7, #CEB380);
        border: solid $c_lab_grey_1 1px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-left: auto; */
        margin-right: 10px;
    //    background: transparent;
        img {
              vertical-align: middle;
              border-style: none;
           width: 100%;
           height: auto;
        }
    }
}

.small-avitar {
   .imgholder {
        height: 32px;
        width: 32px;
       img {
           width: 32px;
       }
   }
}

.seller-products {
    .imgholder {
        height: 80px;
        width: 80px;
        margin-right: 20px;
        flex-shrink: 0;
        svg {
            width: auto;
            height: 84px;
        }

    }

    // span {
    //     text-transform: capitalize;
    //     font-size: 20px;
    // }
}
.seller-listing {
    .imgholder {
        background: linear-gradient(135deg,#F6ECD7, #CEB380);
        border: solid $c_lab_grey_1 1px;
        height: 60px;
        width: 60px;
        margin-right: 22px;
        margin-top: 16px;
        margin-bottom: 16px;
        svg {
            width: 60px;
            height: 60px;
        }
    }

}

.product-wrapper {
    a {
        color: $c_primary;
    outline: none;
    text-decoration: none;


    }

    position: relative;
    padding: 0px 4px;
    box-sizing: border-box;

    // &>* {
    //     // @extend %ease;
    //     // opacity: 0.4;
    // }

    &:hover {
        color: $c_primary;

        &>* {
            opacity: 1;
        }
    }

    &.sold-out {

        &::before {
            content: 'SOLD';
            @include flex(row, center, center);
            position: absolute;
            top: 0px;
            left: 4px;
            color: $c_secondary;
            width: 74px;
            height: 30px;
            background: $c_accent;
            z-index: 1;
        }

        .opacity04 {
            opacity: 0.4;
        }
    }

    &.draft {
        .opacity04 {
            opacity: 0.4;
        }
    }



    &.is-published {

        &>* {
            opacity: 1;
        }
    }



    .product-image {
        width: 100%;
        height: 230px;
    }

    .product-image_outer {
        position: relative;

        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: 133%; // was 138%(157 / 118) * 100%;
        }

        >.product-image_inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            object-position: center;
            object-fit: cover;
            height: 100%;
            border-radius: 8px;
        }
    }

    .product-title {
        margin: -4px 0 12px;
        font-size: 24px;
        line-height: 25px;
    }

    .product-oPrice {
        @extend %title_sm_2;
        @include flex(row, center, start);
        color: $c_accent_3;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .product-bPrice {
        padding-left: 0;
        margin-bottom: 12px;
        font-size: 24px;
        line-height: 25px;
    }

    .product-sizes {
        @include flex(wrap, center, start);
        line-height: 18px;

        span {
            @include flex(row, center, center);
            position: relative;

            &.avail-0 {
                color: $c_accent_3;
            }

            &.product-size {

                &::before {
                    content: '|';
                    display: block;
                    margin: 0 5px;
                    color: lighten($c_accent_3, 10%);
                }

                &:nth-of-type(1) {
                    margin-left: 5px;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}