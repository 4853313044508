.radio-4 {
  $number-of-options: 4;

  .bubble-pos {
    width: 64px;
  }

  input {
    &:checked {
      @for $i from 1 through $number-of-options {
        &:nth-child(#{$i * 2 - 1})~.bubble-pos {
          left: #{($i * 25%) - 12.5%};
        }
      }
    }
  }
}

.radio-2 {
  $number-of-options: 2;

  .bubble-pos {
    width: 120px;
  }

  input {
    &:checked {
      @for $i from 1 through $number-of-options {
        &:nth-child(#{$i * 2 - 1})~.bubble-pos {
          left: #{($i * 50%) - 25%};
        }
      }
    }
  }
   label {

    width: 50% !important;
   }
}

.bubble-slider {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  width: 100%;
  user-select: none;

  input,
  label {
    box-sizing: border-box;
    flex: 1;
    user-select: none;
    cursor: pointer;
  }

  label {
    display: inline-block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    user-select: none;

    &::before {
      content: attr(data-bubble);
      // position: absolute;
      // left: 50%;

      // transform: translate(-50%, 0px);
      // font-size: 14px;
      // letter-spacing: 0.4px;
      // font-weight: 400;
      white-space: nowrap;
      color: $c_accent_3;
      transition: all 0.15s ease-in-out;
      z-index: 1;
    }

    &::after {
      content: " ";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      // border: 2px solid #000;
      // background: #fff;
      border-radius: 50%;
      pointer-events: none;
      user-select: none;

      cursor: pointer;
      transition: all 0.15s ease-in-out;
    }

    &:hover::after {
      transform: translate(-50%, -50%) scale(1.25);
    }
  }

  input {
    display: none !important;

    &:checked {
      +label::before {
        color: black;
      }

      ~.bubble-pos {
        opacity: 1;
      }

      // @for $i from 1 through $number-of-options {
      //   &:nth-child(#{$i * 2 - 1})~#bubble-pos {
      //     left: #{($i * 25%) - 12.5%};
      //   }
      // }
    }
  }

  .bubble-pos {
    display: block;
    position: absolute;
    top: 50%;
    // width: 70px;
    height: 28px;
    border-radius: 35px;
    background: $c_lab_grey_1;
    transition: all 0.15s ease-in-out;
    transform: translate(-50%, -50%);
    // border: 2px solid #fff;
    opacity: 0;
    // z-index: 2;
  }
}

// &:valid {
// 	#bubble-slider {
// 		input {
// 			+ label::before {
// 				transform: translate(-50%, 45px) scale(0.9);
// 				transition: all 0.15s linear;
// 			}
// 			&:checked + label::before {
// 				transform: translate(-50%, 45px) scale(1.1);
// 				transition: all 0.15s linear;
// 			}
// 		}
// 	}
// }