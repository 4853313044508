.accordion-list {
  list-style: none;

  overflow: hidden;
  margin: 0;
   padding: 0;
  // &__item{
  //   &+&{
  //     // border-top: 1px solid $cLightGray;
  //   }
  // }
}

.accordion-item {
  $self: &;

  &--opened {
    #{ $self }__icon {
      // transform: rotate(180deg);
    }
     .arrowicon {
      transform: rotate(135deg) !important;
    }

    #{ $self }__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      // transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
       &.addpadding {
      	padding: 0px 10px;
        .product-wrapper {
          margin-bottom: 10px;
        }
    }
    }

  }

  &__line {
    // align-items: center;
    cursor: pointer;
    outline: none !important;
  }

  &__icon {
    width: 2rem;
    height: 28px;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    // margin-bottom: -4px;
    margin-right: 0px;
    margin-left: auto;
    // transform: rotate(90deg);
  }

  &__arrowin {
    /* Path 7 Copy */

    position: absolute;
    left: 60%;
    right: 0%;
    top: 23.33%;
    bottom: 36.67%;

    border: 2px solid #000000;
    transform: rotate(45deg);
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    // z-index: 1;
    position: relative;
  }

  &__content {
    opacity: 0;
    // transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
  }

  // &__paragraph{
  //   margin: 0;
  //   font-size: 1rem;
  //   color: $cGray;
  //   font-weight: 300;
  //   line-height: 1.3;
  // }

  .arrowicon {
    height: 12px;
    /* overflow: hidden; */
    /* position: absolute; */
    /* left: 220px; */
    /* top: 18px; */
    width: 12px;
    transform: rotate(-45deg);
    transition: transform 0.3s ease-in-out;
    /* right: 10px; */
    	margin-right: 15px;
    margin-left: auto;
    /* margin-top: 4px; */
    /* margin-right: 5px; */
    align-self: center;
    /* box-sizing: content-box; */
    /* display: flex; */
    align-items: center;
    justify-content: space-evenly;
    /* transform-box: view-box; */
    /* transform-origin: top; */
    /* padding-top: 4px; */
    /* padding-right: 4px; */
    transform-origin: 4px 8px;
  }

  .accordion-item__icon {
    width: 12px;
    height: 12px;
    align-self: center;
    background: none !important;
    display: block;

    box-shadow: 2px -2px 0px 0 black inset;
  }

  .accordion-item--opened {


    .accordion-item__icon {
      transform: none;
    }
  }
}