
.no-scroll {
  overflow: hidden;
}
.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000000, 0.50);
  z-index: 100;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-top {
    // margin-top: 50px;
    // align-self: start;
  }

  .modal-outer {
    .modal-inner {
      @include flex(col, center, center);
      position: relative;
      // margin: 0 30px;
      border-radius: 10px;
      background: $c_secondary;
      text-align: center;
      box-sizing: border-box;
      padding: 50px 30px;
      max-width: 339px;

      &.curate-modal {
          padding-top: 60px;
          padding-bottom: 0px;
          padding-left: 45px;
          padding-right: 45px;
      
          .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 0px;
            gap: 14px;
      
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      // &.modal-md {
      //   max-width: 309px;

      // }

      &.modal-sm {
        max-width: 295px;
        padding: 40px 30px;
      }

      &.modal-resolve {
        padding: 20px !important;
        max-width: unset !important;
      }

      &.modal-login {
        max-width: 100%;
        padding: 30px 26px;
        margin: 0px;

        h2 {
          line-height: 1em;
        }

        button.singlesmall {
          margin-top: 48px;
          // padding: 4px 30px 3px 30px !important;
        }
      }

      &.modal-img {
        max-width: 100%;
        padding: 50px 20px 20px;
        max-height: 86vh;

        .confirmWrapper {
          overflow: hidden;
        }
      }

      &.stripemodal {
        // max-width: 309px;
        padding: 30px 20px;
        text-align: left;

        img {
          max-width: 166px;
          text-align: center;
          margin: 0 auto;
          display: block;
        }
      }

      &.rate-modal {
        // margin: 30px;
        max-height: 100%;
        max-width: 600px;
        padding: 24px 20px 32px 20px !important;
      }

      ul {
        padding-inline-start: 1.5em;
          margin-block-start: 0px;
      }

      .close-modal {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .icon {
        width: 81px;
        height: 100px;
        margin: 20px 0 15px;
      }

      .icon2 {

        width: inherit;
        height: inherit;
      }

      p,
      .paragraph {
        margin-bottom: 10px;
        @extend %t_body_sm;
        // Maybe needs to be semi condenced?
      }

      h4 {
        line-height: $r_28;
      }

      .btn-row {
        @include flex(row, center, center);
        margin-top: 30px;
        margin-bottom: 0px;
        font-size: 18px;

        .btn {
          min-width: 84px !important;
          height: 42px !important;
        }
      }
    }
  }
}

.subscription-modal {
  .popup-modal {
    z-index: 100;
  }
    #confettiContainer {
        z-index: 102;
    }
}


img.imgbox {
  max-height: 90vh;
  max-width: 90vw;
  // width: 100%;
}

.close-outer {
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
  }
}


.animborder {
  background: white;
  z-index: 9;
  position: absolute;
  height: 58px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $c_lab_grey_1 !important;

  .modal-title {
    width: 100%;
    text-align: center;
    position: absolute;
    text-transform: capitalize;
  }

  .backbutton {
    margin-left: 30px;
    height: 12px;
    width: 12px;
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out;
    margin-right: 10px;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
    transform-origin: 6px 8px;
    align-self: center;
    background: none !important;
    display: block;
    box-shadow: 2px -2px 0px 0 black inset;

  }
}

.fixed-modal {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  padding: 0px;

  box-sizing: border-box;

  .scrollwrapper {
    overflow-y: scroll;
    height: 100%;

    align-items: flex-start;
    flex-flow: nowrap;

    .printlabel {
      /* width: 100%; */
      background: white;
      max-width: 354px;
      padding-right: 30px;
      padding-left: 30px;
      padding-top: 58px;
      margin-bottom: 56px;
      padding-bottom: 96px;

      margin-left: auto;
      margin-right: auto;
    }
  }
}

