@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;400;500&family=Barlow+Semi+Condensed:wght@400;500&family=Montserrat:wght@700&family=Playfair+Display:ital@0;1&display=swap');
// Barlow Condensed thin:100 reg:400 med:500
// Barlow Semi Condensed reg:400 med:500
// Montserrat bold:700
// Playfair Display reg:400

// @import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
// @import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

// Typography
$f_title: "Playfair Display",
serif;
$f_desc: "Playfair Display",
serif;
$f_body: "Barlow Semi Condensed",
sans-serif;
$f_h1: "Barlow Condensed",
sans-serif;
$f_h2: "Barlow Condensed",
sans-serif;
$f_h3: "Barlow Condensed",
sans-serif;
$f_h4: "Barlow Condensed",
sans-serif;
$f_h5: "Barlow Condensed",
sans-serif;
$f_h6: "Barlow Condensed",
sans-serif;
$f_nav: "Barlow Condensed",
sans-serif;
$f_btn: "Montserrat",
sans-serif;

// SIZES
$full: 100%;
$half: 49%;
$third: 32%;
$fourth: 23%;
$fifth: 19%;

// ROW HEIGHTS
$r_xs: 24px;
$s_xs: 24px;
$r_sm: 3em;
$s_sm: 40px;
$s_sm-x: 28px;
$r_md: 3.45em;
$s-50: 50px;
$s-60: 60px;
$s_md: 80px;
$s_100: 96px;
$r_lg: 80px;
$s_lg: 4em;
$r_xl: 140px;
$s_xl: 4.5em;
$r_28: 28px;

%r-xs {
    line-height: $r_xs;
}

%s-xs {
    height: $s_xs;
}

%r-sm {
    line-height: $r_sm !important;
}
%r-sm-x {
    line-height: 2em !important;
}

%s-sm {
    height: $s_sm;
}

%s-sm-x {
    height: $s_sm-x;
}

%r-md {
    line-height: $r_md;
}

%s-md {
    height: $s_md;
}

%r-lg {
    line-height: $r_lg;
}

%s-lg {
    height: $s_lg;
}

%r-xl {
    line-height: $r_xl;
}

%s-xl {
    height: $s_xl;
}

%h-60 {
    height: $s-60;
}

$c_primary: #000000;
$c_secondary: #ffffff;
$c_accent: #f05f40;
$c_light_green: #DCEFD5;
$lab_grey_0: #f2f2f2;
$c_lab_grey_1: #DBDBDB;
$c_accent_2: #c3c3c3; // darken($c_accent, 10%);
$c_accent_3: #949494;
$c_accent_4: #686868;
$c_bg: #f2f2f2; //rgba($c_accent_3, 0.23);
$c_notice: #f6f6f6;
$c_link: $c_accent;
$c_toggle: #3B9B15; // 3B9B15
$c_green: #3B9B15; // #75b857; // 3B9B15
$c_red: #F52828;
$c_labgrey: rgba($c_lab_grey_1, .7);
$c_labgreen: rgba($c_green, .12);
// $c_labgreen10: rgba($c_green, .12);
$c_labgreen25: rgba($c_green, .25);
$c_labred: rgba($c_accent, .15);
$c_labred30: rgba($c_red, .30);



%t_error {
    font-family: $f_body;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    color: $c_accent;
}

%t_body {
    font-family: $f_body;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
}
%t_body_cond {
    font-family: $f_body;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
}

%t_body_xs {
    font-family: $f_h6;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

%t_body_sm {
     font-family: $f_h6;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
}

%t_body_sm_cond {
    font-family: $f_h6;
    font-size: 17px;
    font-weight: 500;
}

%t_body_lg_cond {
    font-family: $f_h6;
    font-size: 22px;
      line-height: 26px;
    font-weight: 400;
}

%t_body_lg {
    font-family: $f_body;
    font-size: 22px;
    font-weight: 400;
     line-height: 26px;
}

%title_sm {
    font-family: $f_h6;
    font-weight: 700;
    font-size: 15px;
}

%title_sm_1 {
    font-family: $f_h6;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;

}

%title_sm_2 {
    font-family: $f_body;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
}

%title_sm_3 {
    font-family: $f_h6;
    font-weight: 400;
    font-size: 14px;
}

%title_sm_2_sc {
    font-family: $f_body;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
}

%heading {
    margin: 0;
}

%h42 {
    @extend %heading;
    font-family: $f_h1;
    font-size: 42px;
    font-weight: 400;
    line-height: 1em;
}

%h40 {
    @extend %heading;
    font-family: $f_h1;
    font-size: 40px;
    font-weight: 400;
    line-height: 1em;
}

%h1 {
    @extend %heading;
    font-family: $f_h1;
    font-size: 40px;
    font-weight: 400;
    line-height: 1em;
}

%h2 {
    @extend %heading;
    font-family: $f_h2;
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
}

%h3 {
    @extend %heading;
    font-family: $f_h3;
    font-size: 26px;
    font-weight: 500;
    line-height: 28px;
}

%h4 {
    @extend %heading;
    font-family: $f_h4;
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
}

%h5 {
    @extend %heading;
    font-family: $f_h5;
    font-size: 20px;
    font-weight: 400;
}

%h6 {
    @extend %heading;
    font-family: $f_h6;
    font-size: 18px;
    font-weight: 400;
}


// Map of breakpoints
$breakpoints: (small: 320px,
    medium: 600px,
    large: 768px,
    xlarge: 960px,
    xxlarge: 1024px,
    desktop: 1280px,
);

%filter {
    border-bottom-color: $c_accent;
    border-bottom-width: 1px;
    color: $c_primary;
}

%active-green {
    // rgba($c_toggle, .10);
    background-color: $c_labgreen !important;

  //  color: $c_toggle !important;
}


// Respond-To Mixin
@mixin respond-to($breakpoint, $direction) {
    @if map-has-key($breakpoints, $breakpoint) {
        @if $direction=="min" {
            $value: map-get($breakpoints, $breakpoint);

            @media screen and (min-width: $value) {
                @content;
            }
        }

        @if $direction=="max" {
            $value: map-get($breakpoints, $breakpoint);

            @media screen and (max-width: $value) {
                @content;
            }
        }
    }

    @else {
        @if $direction=="min" {
            $value: $breakpoint + "px";

            @media screen and (min-width: $value) {
                @content;
            }
        }

        @if $direction=="max" {
            $value: $breakpoint + "px";

            @media screen and (max-width: $value) {
                @content;
            }
        }
    }
}

// SPACING
$pad: 30px;
$pad_small: 20px;

%ease {
    -webkit-transition: all 375ms ease-out;
    -moz-transition: all 375ms ease-out;
    -o-transition: all 375ms ease-out;
    transition: all 375ms ease-out;
}

%link {
    @extend %ease;
    color: $c_link;
    outline: none;
    text-decoration: none;

    &:focus,
    &:active {
        color: $c_link;
    }

    &:hover {
        cursor: pointer;
        color: $c_accent_2;
    }
}

%alt_link {
    @extend %ease;
    color: $c_primary;
    outline: none;
    text-decoration: none;

    &:focus,
    &:active,
    &:visited {
        color: $c_primary;
    }

    &:hover {
        cursor: pointer;
        color: $c_link;
    }
}

%white_link {
    @extend %ease;
    color: $c_secondary;
    outline: none;
    text-decoration: none;

    &:focus,
    &:active,
    &:visited {
        color: $c_secondary;
    }

    &:hover {
        cursor: pointer;
        color: $c_link;
    }
}

@mixin flex($direction: null, $align: null, $justify: null) {
    display: -webkit-flex !important;
    display: flex !important;

    @if $direction=="row" {
        flex-direction: row !important;
        -webkit-flex-direction: row !important;
    }

    @if $direction=="row-rev" {
        flex-direction: row-reverse !important;
        -webkit-flex-direction: row-reverse !important;
    }

    @if $direction=="col" {
        flex-direction: column !important;
        -webkit-flex-direction: column !important;
    }

    @if $direction=="col-rev" {
        flex-direction: column-reverse !important;
        -webkit-flex-direction: column-reverse !important;
    }

    @if $direction=="wrap" {
        flex-wrap: wrap !important;
        -webkit-flex-wrap: wrap !important;
    }

    @if $align=="start" {
        align-items: flex-start !important;
        -webkit-align-items: flex-start !important;
    }

    @if $align=="center" {
        align-items: center !important;
        -webkit-align-items: center !important;
    }

    @if $align=="end" {
        align-items: flex-end !important;
        -webkit-align-items: flex-end !important;
    }

    @if $justify=="start" {
        justify-content: flex-start !important;
        -webkit-justify-content: flex-start !important;
    }

    @if $justify=="center" {
        justify-content: center !important;
        -webkit-justify-content: center !important;
    }

    @if $justify=="around" {
        justify-content: space-around !important;
        -webkit-justify-content: space-around !important;
    }

    @if $justify=="between" {
        justify-content: space-between !important;
        -webkit-justify-content: space-between !important;
    }

    @if $justify=="end" {
        justify-content: flex-end !important;
        -webkit-justify-content: flex-end !important;
    }
}

%nav {
    display: -webkit-flex;
    display: flex;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: none;

    li {
        position: relative;
        float: none;
        padding: 0 10px;
        white-space: nowrap;

        .sub-menu {
            @extend %ease;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            margin: 0;
            list-style: none;
            transform: translate(-35%, 0);
            top: 100%;
            text-align: left;
            padding: 15px 30px;
            background: $c_secondary;
            white-space: nowrap;
            box-shadow: unset;
            z-index: 100;

            li {
                padding: 5px 0;
            }

            a {
                width: auto;
                color: $c_primary;
            }
        }

        &:hover {
            .sub-menu {
                opacity: 1;
                visibility: visible;
            }
        }

        .icon {
            position: relative;
            top: -1px;
            width: 10px;
            height: 10px;
            margin-left: 4px;
        }
    }
}

%container {
    // width: 85%;
    max-width: 955px;
    margin: 0 auto !important;
}

%site-contain {
    max-width: 1024px;
    margin: 0 auto;
}

%blackline {
    border-bottom: 1px solid #000000 !important;
}

%greyline {
    border-bottom: 1px solid $c_lab_grey_1;
}
%darkgreyline {
    border-bottom: 1px solid $c_accent_4;
}


%btn {
    @include flex(row, center, center);
    padding: 4px 30px 3px 30px !important;
    font-family: $f_btn;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    text-align: center !important;
    line-height: 2em !important;
    cursor: pointer;

    // &.lg-btn {
    //     min-width: 218px;
    // }
    &.fat {
        border-width: 4px !important;
        font-size: 20px !important;
    }

    svg {
        margin-right: 15px;
    }

    &:disabled {
        border-color: $c_lab_grey_1 !important;
        color: $c_lab_grey_1 !important;

        &:focus,
        &:hover {
            border-color: $c_lab_grey_1;
            color: $c_lab_grey_1;
        }
    }
}

%btn-active {
    @extend %btn;
    background: $c_accent !important;
    border: 1.5px solid $c_accent !important;
    color: $c_primary !important;

    a {
        color: $c_primary !important;
    }

    .icon,
    &.icon {
        fill: $c_primary !important;
    }

    &:hover {
        background: white !important;
        color: $c_primary !important;

        a {
            color: $c_primary !important;
        }

        .icon {
            fill: $c_primary !important;
        }
    }
}

%btn-primary {
    @extend %btn;
    background: $c_primary !important;
    border: 1.5px solid $c_primary !important;
    color: $c_secondary !important;

    a {
        color: $c_secondary !important;
    }

    .icon,
    &.icon {
        fill: $c_secondary !important;
    }

    &:hover {
        background: white !important;
        color: $c_primary !important;

        a {
            color: $c_primary !important;
        }

        .icon {
            fill: $c_primary !important;
        }
    }
}

%btn-secondary {
    @extend %btn;
    background: $c_secondary !important;
    border: 1.5px solid $c_secondary !important;
    color: $c_primary !important;

    a {
        color: $c_primary !important;
    }

    .icon,
    &.icon {
        fill: $c_primary !important;
    }

    &:hover {
        background: $c_primary !important;
        color: $c_secondary !important;

        a {
            color: $c_secondary !important;
        }

        .icon {
            fill: $c_secondary !important;
        }
    }
}

%btn-pill {
    @extend %btn;
      transition: 200ms ease-out;
    background: $c_secondary !important;
    border: 2px solid $c_primary !important;
    border-radius: 2em 2em 2em 2em !important;
    color: $c_primary;
    margin: 0 0px;
    line-height: 2em !important;
    	white-space: nowrap;

    a {
        color: $c_primary;
    }

    .icon,
    &.icon {
        fill: $c_primary !important;
    }

    &:hover {
        border-color: $c_accent;
    }
}

%singleBtn {
    // min-width: 164px;
    padding: 4px 24px 3px !important;
}

%sm-btn {
    padding: 0px 16px 0px 16px !important;
    // line-height: 17px !important;
    align-self: center;
    font-size: 14px !important;
  //  color: black !important;
      height: 37px;
  box-sizing: border-box;
    font-weight: 500 !important;
    // font-family: $f_body !important;
}

%md-btn {
    height: 42px;
    min-width: 129px;
    font-size: 16px !important;
    border-width: 2px !important;
    padding: 0px 16px 0px 16px !important;
    line-height: 19.5px !important;
    align-self: center;
   // color: black !important;
}

%adjust-center {
    &>*:nth-child(1) {
        margin-right: auto;
        flex: 1;
    }

    &>*:nth-child(2) {
        flex: 2;
    }

    &>*:nth-child(3) {
        margin-left: auto;
        flex: 1;
    }
}

