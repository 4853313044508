$rate-prefix-cls: rc-rate;
$rate-star-color: $c_accent;
$font-size-base: 12px;

.#{$rate-prefix-cls} {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;

  &-rtl {
    direction: rtl;
  }

  &-disabled &-star {
    cursor: default;
    &:before,
    &-content:before {
      cursor: default;
    }
    &:hover {
      transform: scale(1);
    }
  }

  &-star {
    margin: 0;
    padding: 0;
    display: inline-block;
    // margin-right: 18px;
    position: relative;
    transition: all .3s;
    color: $lab_grey_0;
    color: $c_accent_2;
    cursor: pointer;
    line-height: 1;
    outline: none;

    &:last-child {
      margin-right: 0px;
    }
    &-first,
    &-second {
      transition: all .3s;
    }

    &-focused, &:hover {
      transform: scale(1.1);
    }

    &-first {
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      overflow: hidden;
      opacity: 0;


    }

    &-half &-first,
    &-half &-second {
      opacity: 1;
    }

    &-half &-first,
    &-full &-second {
      color: $rate-star-color;
    }

    &-half:hover &-first,
    &-full:hover &-second {
      color: tint($rate-star-color,30%);
    }

  }
}

$icon-url: "//at.alicdn.com/t/font_r5u29ls31bgldi";

@font-face {
  font-family: 'anticon';
  src: url('#{$icon-url}.ttf') format('truetype'); /* IE9*/
//  src: url('$icon-url.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('{$icon-url}.woff') format('woff'), /* chrome、firefox */ url('{$icon-url}.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('{$icon-url}.svg#iconfont') format('svg'); /* iOS 4.1- */
}

.anticon {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    display: block;
    font-family: "anticon" !important;
  }
}

.anticon-star:before { content: "\e660"; };