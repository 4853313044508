.item-container {
    max-width: 500px;
    margin: 0 auto;
    margin-top: -20px;

    #gallerySlider {
        position: relative;
        width: 100%;
        .loading-img {
        height: calc(133.333vw - 80px);
            max-height: 666px;
            // background-color: black;
        }

                // .slide-image {
                //     opacity: 0;
                //     transition: opacity 0.5s ease-in-out;
                // }
        
                // .fade-in {
                //     opacity: 1;
                // }
        
                // .fade-out {
                //     opacity: 0;
                // }
    }

    .slick-slider {
        .slick-track {
            display: flex;
            height: calc(133.333vw - 80px);
            max-height: 666px;

            .slick-slide {
                display: flex;
                // height: auto;
                // position: relative;
                // box-sizing: content-box;
                /* align-items: center; */
                /* justify-items: center; */
                text-align: center;
                width: 100%;
                justify-content: center;

                img {
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }

    .slick-slider {
        margin-bottom: 47px;

        .slick-dots {

            li {
                margin: 0;

                button {

                    &::before {
                        font-size: 9px;
                    }
                }
            }
        }
    }
}

.successful-purchase {
    @include flex(col, center, stretch);
    // min-height: calc(100vh - 124px);
    justify-content: center;
    text-align: center;

    // min-height: calc(100vh - 148px);
    .successwrapper {
        max-width: 354px;
        margin-top: 56px;
    }

    .login-form {
        // background: white;
        z-index: 9;
        position: relative;
    }

    .field-group {
        text-align: left;
    }

    h1 {
        margin-bottom: 12px;
        font-size: 40px;
        line-height: 40px;
    }

    .rednote {
        color: rgb(240, 95, 64);
        font-family: "Barlow Condensed", "Helvetica Neue", sans-serif;
        font-size: 20px;
        font-weight: 400;
    }

    p {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 18px;
    }
}

.bgreytop {
    border-top: 1px solid $c_lab_grey_1 !important;
}

.checkout-form {
    width: 100%;
    background: white;

    // &.loading {
    //     .overlay {
    //         @include flex(row, center, center);
    //         position: fixed;
    //         top: 0;
    //         left: 0;
    //         right: 0;
    //         bottom: 0;
    //         z-index: 1;

    //         svg {
    //             width: 100px;
    //             height: 100px;
    //         }
    //     }
    // }
    .label-wrap label {
        line-height: 1em;
    }

    // small {
    //     @include flex(row, center, center);
    //     width: 100%;
    //     text-align: center;
    //     color: $c_accent_4;
    //     font-size: 16px;
    // }
    .checkout-header {

        .item-total-row {
            @include flex(row, center, between);
            border-top: 1px solid $c_primary;
            border-bottom: 1px solid $c_primary;
            padding: 24px 0;

            .total-label {
                text-transform: uppercase;
                font-family: $f_body;
                font-size: 25px;
                font-weight: 500;
            }
        }




        &.form {
            .field-group {
                // border-top: 1px solid #D6D6D6;

                // .label-wrap {
                //     label {
                //         font-size: 24px;
                //         font-weight: bold;
                //     }
                // }

                .field-wrap {

                    .field-element {
                        @include flex(row, center, end);
                        padding: 30px 0;
                        font-size: 38px;

                        &.money {
                            @include flex(row, start, end);
                        }
                    }
                }
            }
        }
    }

    form,
    .form {
        border-top: none !important;

        &.close-outer {
            margin: 24px auto 50px;
        }
    }
}

.item-op-row {
    @include flex(row, center, between);
    // border-top: 1px solid $c_primary;
    border-top: 1px solid $c_lab_grey_1;
    padding: 15px 0;

    .op-label {
        // text-transform: uppercase;
        // font-family: $f_body;

        @extend %title_sm_2;

        white-space: pre-wrap;
        font-size: 15px;
        line-height: 15px;
    }
   
    .op-amount {
     
    }

    // .op-amount {
    //     @include flex(row, center, end);
    //     color: #686868;
    //     line-height: 28px;
    //     // margin-top: 21px;

    //     .money {
    //         padding-left: 10px;
    //         padding-right: 0;
    //     }
    // }
}


#checkoutForm {

    // padding-top: 50px;
    .checkcoupon {
        .hf-warning {
            width: 100%;
        }

        button {
            z-index: 9;
        }
    }

    .valbtn {
        padding: 0px 16px 0px 16px !important;
        line-height: 17px !important;
        align-self: center;
        font-size: 14px !important;
        color: black !important;
        height: 34px;
    }

    small {
        @include flex(row, center, center);
        width: 100%;
        line-height: 20px;
        color: $c_accent_4;
        font-size: 16px;
        // @extend %greyline;
        align-self: auto;
        padding-top: 2px;

        text-align: left;
        justify-content: flex-start !important;

        &.centered {
            border: none !important;
            padding-bottom: 0px;
            text-align: center;
            line-height: 24px;
        }
    }

    .purchase-disclaimer {
        @include flex(row, center, start);
        padding: 80px 0 60px;

        label {
            @extend%t_body_sm;
            margin-top: -1.53em;
        }

        input {
            z-index: 1;
            width: unset;
            margin-right: 23px;
            width: 29px;
            height: 22px;
            outline: 2px solid black;
            outline-offset: -1px;

            &:focus {
                width: 30px !important;
                height: 22px !important;
                outline: 2px solid black !important;
                outline-offset: -1px !important;
            }
        }

        // span {
        //     @extend %t_body_xs;
        // }
    }

    .verify {
        padding-top: 65px;
        padding-bottom: 86px;
    }

    .field-group {



        .nopadleft {
            padding-left: 0px !important;
        }

        .blackborderb {
            border-bottom: 1px solid #000000 !important;
            margin-bottom: -1px;

            input {

                border-bottom: none !important;
            }
        }

        .submit-wrap {
            margin-top: 5px !important;
            @include flex(row, center, center);

            .btn-pill,
            button {
                // line-height: 3.2em !important;
                z-index: 9;
                // border-width: 4px !important;
                // font-size: 20px !important;
            }
        }
    }
}


//     .overlay {
//         @include flex(row, center, center);
//         position: fixed;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         z-index: 1;
//   background-color: rgba(255,255,255,0.9);
//     }

.hr {
    @extend %greyline;
    width: 100%;
}

.item-title {
    margin-bottom: 24px;
}

h4.checkoutsize {
    /* text-transform: uppercase; */
    color: $c_accent_4;
    font-size: 20px;
    padding-bottom: 31px;
    padding-top: 6px;
}

span.item-size-qty {
    color: black;
    /* font-weight: 500; */
    font-size: 24px;
}

.item-container {
    .item-form-wrap {
        position: relative;

        .field-group {
            .lessbold {
                font-weight: 400 !important;

            }

            &.item-prices {
                .field-wrap {
                    .item-size {
                        position: realtive;
                    }

                    .field-element {
                        padding: 25px 0 25px 0px;
                        margin: 0 !important;

                        &>* {
                            @extend %t_body;
                            flex: unset;

                        }

                        .money {
                            padding-right: 10px;

                            &:first-child {
                                @extend %h1;
                            }
                        }

                        .item-oPrice {
                            @include flex(row, center, end);
                            color: $c_accent_4;
                            line-height: 28px;
                            margin-top: 21px;

                            .money {
                                padding-left: 10px;
                                padding-right: 0;
                            }
                        }

                    }

                    .label-element {}

                }
            }

            &.item-size {
                .field-wrap {
                    .field-element {
                        padding: 15px 0 15px 0px
                    }

                    .item-size-qty {
                        padding-bottom: 5px;

                        .qty {
                            min-width: 83px;
                        }
                    }

                    .add-watch-btn {
                        margin-left: 8px;

                        svg {
                            width: 42px;
                            height: 42px;
                        }
                    }
                }
            }

            .label-wrap {
                //    flex: 0.45 !important;

                .label-element {
                    @extend %title_sm_2;
                    color: #6a6a6a;
                    white-space: pre-wrap;
                    font-size: 15px;
                    line-height: 15px;

                }
            }

            .field-wrap {
                .field-element {
                    @include flex(row, center, between);
                    padding: 18px 0 22px 0px;
                    // font-size: 28px;
                    box-sizing: border-box;
                    line-height: 28px;
                    @extend %h3;
                    // & > * {
                    //     flex: 1;
                    // }
                }
            }

            // button, .btn {
            //     min-width: 130px;
            // }
        }

        .item-details {


            p {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}

.outofstock {
    .lowero7 {
        opacity: 0.7
    }

    svg {
        width: 81px;
        height: 79px;
    }
}

.modal-animate {
    position: relative;
    width: 100%;

   &> .formx {
        opacity: 0;
        transition: opacity 250ms ease-out;
        -webkit-transition: opacity 250ms ease-out;
    }

    &>.marginslider {
        margin-left: 100%;
        transition: margin-left 250ms ease-out;
          -webkit-transition: margin-left 250ms ease-out;
    }
}

// .checkoutmodal-enter {
//    &> .formx {
//         opacity: 0;
//         transition: opacity 1300ms ease-out;
//     }

//    &> .marginslider {

//         margin-left: 100%;
//         transition: margin-left 1300ms ease-out;
//     }
//     // margin-left: 100%;
// }

//  .checkoutmodal-enter-active {
//      &>.formx {
//         opacity: 0;
//         transition: opacity 2300ms ease-out;
//     }

//     &>.marginslider {
//           background: black;
//         margin-left: 0%;
//         transition: margin-left 2300ms ease-out;
//     }
//  }
.checkoutmodal-enter-active,
.checkoutmodal-enter-done {
   &> .formx {
        opacity: 1;
    }

   &> .marginslider {
        margin-left: 0%;
    }
}

.checkoutmodal-exit {
    .formx {
        opacity: 0;
        transition: opacity 300ms ease-in;
    }

    .marginslider {
        margin-left: 100%;
        transition: margin-left 250ms ease-in;
    }
}

.chkoutsignup {
    margin-top: 97px;
}

.modal-bottom-collapse {
    transition: margin-top 375ms ease-in-out;
    margin-top: 0%;

    &.modal-bottom-collapse-out {
        margin-top: 100%;
        opacity: 1;
    }
}

// new modal animation

.modal-slideup-animate {
    position: relative;
    width: 100%;
    opacity: 0;
    transition: opacity 300ms ease-out;
    -webkit-transition: opacity 300ms ease-out;
    .modal-outer {
        margin-top: 30%;
        //  opacity: 0;
        transition: margin-top 200ms ease-out, opacity 375ms ease-in-out;
        -webkit-transition: margin-top 200ms ease-out, opacity 375ms ease-in-out;
    }
}


.modal-slideup-enter {
    opacity: 0;

    .modal-outer {
        margin-top: 30%;
        //  opacity: 0;
    }

}

.modal-slideup-enter-active,
.modal-slideup-enter-done {
    opacity: 1;

    .modal-outer {
        margin-top: 0%;
        //  opacity: 1;
    }
}

.modal-slideup-exit {
    opacity: 0;
    transition: opacity 375ms ease-in-out;

    .modal-outer {
        margin-top: 0%;
        //  opacity: 0;
        //  transition: margin-top 375ms ease-in-out, opacity 675ms ease-in-out;
        //  transition: margin-top 675ms ease-out, opacity 875ms ease-in-out;
    }
}

// .modal-slideup-exit-done{
//     display: none;
// }


// .checkoutmodal-exit-active {


// }
.animdelay {
  position: relative;
    width: 100%;
      .formx {
        opacity: 0;
         transition: opacity 250ms ease-out;
    }

    .marginslider {
        margin-left: 100%;
         transition: margin-left 250ms ease-out;
    }
}

.checkoutmodaldelay-enter-active {
       .formx {
        opacity: 0;
    }
    .marginslider {
        margin-left: 100%;
        // transition: margin-left 250ms ease-out;
    }
}
.checkoutmodaldelay-enter-done {
    .formx {
        opacity: 1;
    }

    .marginslider {
        margin-left: 0%;
    }
}

.checkoutmodaldelay-exit {
    .formx {
        opacity: 0;
        transition: opacity 300ms ease-in;
    }

    .marginslider {
        margin-left: 100%;
        transition: margin-left 250ms ease-in;
    }
}
