.mms {

  textarea.textareaSMS {
    padding: 5px 0px;
    width: 100%;
    box-sizing: border-box;
    @extend %blackline;
  }

.card-previewMMS {
  
display: flex !important;
flex-direction: column !important;
align-items: center !important;
-webkit-align-items: center !important;
justify-content: space-between !important;
-webkit-justify-content: space-between !important;
/* padding: 55px 0px 15px; */
position: relative;
margin: 0 auto;
background-color: white;
text-align: center;
width: 100%;
min-height: 354px;
box-sizing: border-box;

}
.historyTable {
    width: 100%;
    text-align: left !important;
    tr {
      td {
          text-align: left !important;
      }
    }
}
}