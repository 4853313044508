#colophon {
    position: relative;
    padding: 20px;
    background: $c_bg;
    color: $c_accent_3;
    
    a {
        font-family: $f_nav;
        font-weight: 400;
        color: $c_accent_3;

        &:hover {
            color: $c_link;
        }
    }

    .footer-links {
        
        .footer-link {
            padding: 0 10px;
            border-left: 1px solid $c_accent_3;
            line-height: 0.75em;
    
            &:first-child {
                border-left: none;
            }
        }
    }

    @media only screen and (max-width: 643px) { 
        font-size: 16px;
    }
}