button {
  &:disabled {
    border-color: $c_lab_grey_1 !important;
    color: $c_lab_grey_1 !important;
  }
}
.pre-login-btns {
  @include flex(row, center, center);

  .btn {
    width: 84px;
  }

  span {
    margin: 0 15px;
  }
}

.btn-row {
  @include flex(row, center, center);
  margin-bottom: 10px;

  .btn {
    margin-right: 10px;
  }

  .btn:last-child {
    margin-right: 0px !important;
  } 
  
}

a.btn,
.btn {
  @extend %btn;
  &.disabled {
      border-color: $c_lab_grey_1 !important;
      color: $c_lab_grey_1 !important;
    }
}

a.btn-primary,
.btn-primary {
  @extend %btn-primary;
}

a.btn-secondary,
.btn-secondary {
  @extend %btn-secondary;
}

a.btn-pill,
.btn-pill {
  @extend %btn-pill;

  &.checkout-btn {
    height: 60px;
    box-sizing: border-box;
    // min-width: 260px;
  }
}
.btn-short {
  // width: 80px;
  padding: 4px 16px 3px 16px !important

}

a {
  &.sm-btn {
    @extend %sm-btn;
  }
}
input[type="button"],
button,

.button {
  cursor: pointer;
  @extend %btn-pill;

  &.sm-btn {
    @extend %sm-btn;
  }

  &.md-btn {
    @extend %md-btn;
  }

  &.lg-btn {
    height: 60px;
    // min-width: 297px;
    border-width: 4px !important;
    padding: 4px 24px 3px !important;
    // padding: 4px 50px 3px !important;
    font-size: 20px !important;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .redbtn {
    border-color: $c_accent;
    background: $c_labred;
  }

  &.p-50 {
    padding: 4px 50px 3px !important;
  }

  &.w-300 {
    min-width: 300px;
  }

  &.disabled {
    border-color: $c_lab_grey_1 !important;
    color: $c_lab_grey_1 !important;
  }

  &.verified {
    color: $c_green !important;
  }

  &.verified:disabled {
    border-color: #f4f4f4 !important;
    color: $c_green !important;
  }
  &.green-btn {
    border-color: $c_green !important;
      background: transparent !important;
    color: $c_green !important;
    &:disabled, &:hover {
      border-color: rgba($color: $c_green, $alpha: 0.4) !important;
      color: rgba($color: $c_green, $alpha: 0.4) !important;
    }

  }

  &.red-btn {
    border-color: $c_red !important;
      background: transparent !important;
    color: $c_red !important;
    &:disabled, &:hover {
      border-color: $c_labred30 !important;
      color: $c_labred30 !important;
    }

  }

  &.grey-btn {
    border: 2px solid $c_lab_grey_1 !important;
    &:hover { border: 2px solid $c_primary !important; }
  }
  &.grey-fill-btn {
    background: $lab_grey_0 !important;
    border-width: 0px !important;
      justify-content: start;
      &:hover {
          background: $c_lab_grey_1 !important;
          border: 0px solid $c_primary !important;
        }
  }

  &.singleBtn {
    @extend %singleBtn;
  }
  &.match-sold-btn {
    	width: 132.6px;
  }

  // &.smbtn {
  //   height: 42px;
  //   min-width: 229px;
  //   font-size: 16px !important;
  //   padding: 0px 6px 0px !important;
  //   border-width: 4px !important;

  //   svg {
  //     margin-top: -1px;
  //     width: 15px;
  //     height: 15px;
  //   }
  // }
}

a.singleBtn,
.confirm-modal .singleBtn {
  @extend %singleBtn;
  width: min-content;
  margin: 0 auto;
  white-space: nowrap;
}

.switch-toggle {

  $tw-border-spacing-x: 0;
    $tw-border-spacing-y: 0;
    $tw-translate-x: 0;
    $tw-translate-y: 0;
    $tw-rotate: 0;
    $tw-skew-x: 0;
    $tw-skew-y: 0;
    $tw-scale-x: 1;
    $tw-scale-y: 1;
    $tw-scroll-snap-strictness: proximity;
    $tw-ring-offset-width: 0px;
    $tw-ring-offset-color: #fff;
    $tw-ring-color: rgba(59, 130, 246, 0.5);
    $tw-ring-offset-shadow: 0 0 transparent; // Using 'transparent' instead of '#0000'
    $tw-ring-shadow: 0 0 transparent; // Using 'transparent' instead of '#0000'
    $tw-shadow: 0 0 transparent; // Using 'transparent' instead of '#0000'
    
   
  
    *,
    ::after,
    ::before {
      box-sizing: border-box;
      border-width: 0;
      border-style: solid;
      border-color: #e5e7eb;
    }
  
    ::after,
    ::before {
      $tw-content: "";
    }
  
    :disabled {
      cursor: default;
    }
  

        .w-48 {
          width: 12rem;
        }
  
        .h-9 {
          height: 2.25rem;
        }
  
        .bg-gray-200 {
          $tw-bg-opacity: 1;
          background-color: rgb(229 231 235 );
        }
  
        .rounded-full {
          border-radius: 9999px;
        }
  
        .p-1 {
          padding: 0.25rem;
        }
  
      
          .left-0 {
            left: 0px;
          }
          .absolute {
            position: absolute;
          }
  
          .w-1\/2 {
            width: 50%;
          }
  
          .h-full {
            height: 100%;
          }
  
          .rounded-full {
            border-radius: 9999px;
          }
  
          .transition-transform {
            transition-property: transform;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
  
            .duration-300 {
              transition-duration: 300ms;
            }
          }
  
          .transform {
            transform: translate(0px, 0px);
          }
  
          .translate-x-0 {
          
            transform: translate(0%, 0px);
          }
          .translate-x-full {
            --tw-translate-x: 100%;
            transform: translate(100%, 0)
          }
  
          .bg-black {
            $tw-bg-opacity: 1;
            background-color: rgb(0 0 0);
          }
        
  
          .z-10 {
            z-index: 10;
          }
  
          span {
            &.w-1\/2 {
              width: 50%;
            }
  
            &.text-center {
              text-align: center;
            }
  
            &.text-white {
              $tw-text-opacity: 1;
              color: rgb(255 255 255 );
            }
  
            &.text-gray-500 {
              $tw-text-opacity: 1;
              color: rgb(107 114 128 );
            }

    }
}