.profileimage {
    .card-preview-start {
        @include flex(row, center, center);
        padding: 50px;
        text-align: center;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    }

    .card-preview-wrapper {
        border-radius: 50%;
        height: 200px;
        width: 200px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: auto;
        margin-right: auto;

        img {
            width: 100%;
        }
    }

    .profile-img-wrapper {
        background: linear-gradient(135deg,#F6ECD7, #CEB380);
        border-radius: 50%;
        height: 132px;
        width: 132px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid $c_lab_grey_1 1px;

        margin-left: auto;
        margin-right: auto;

        img {
            width: 100%;
        }

    &.feedbackimg {
        height: 84px;
        width: 84px;
    }
    }

    .wideprovider {
        position: relative;
        padding-bottom: 5px;
        padding-top: 5px;
    }
}

.profile_wrapper {
    a.small {
        font-size: 20px;
        font-weight: 500;
    }
}

.iconpadder {
    padding-right: 7px;
}

.coupons {
    small {
        color: $c_accent_4;
        line-height: 1em;
    }
}



.modal-address {
padding: 12px 0px;
}



.profile_wrapper {
    .label-wrap.about {
        border-bottom: none !important;
    }

    textarea {
        @extend %t_body;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        width: 100%;
        padding: 0rem 1rem;
        box-sizing: border-box;
        border: 1px solid $c_lab_grey_1 !important;
        height: 300px;
    }
}

.hlimitmodal{
  max-height: 300px;
  overflow-y: scroll;
  text-align: left;
  max-height: 480px;
  h1, h2, h3, h4 {
    margin-bottom: 1rem;
  }
  li {
    margin-bottom: .3rem;
  }
//   display: flex;
  p {
    font-size: 16px !important;

  }

  .loaderSm {
    min-width: 279px;
    margin: 0 auto;
  align-self: center;
  svg {
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
  }
  
}

// #flakes i:nth-of-type(4n+1) {
// 	animation-name: fourn;
// }
// @keyframes fourn {
// 	0% {
// 		transform:
//             translate3d(0, 0, 0) rotate(50deg) scale(0.9, 0.9);
//             transform-origin: 0px 0px;
// 	}
// 	50% {
// 		transform:
//             translate3d(10, 500px, 0) rotate(0deg) scale(1, 1);
//             transform-origin: 0px 0px;
// 	}

// 	100% {
// 		transform:
//             translate3d(30px, 1100px, 0) rotate(-90deg) scale(1.1, 1.1);
//             transform-origin: 0px 0px;
// 			opacity: 0
// 	}
// }


#flakes i {
    position: relative;
    z-index: -1;
    animation-name: falling;
}

@keyframes falling {
    0% {
        transform: translate3d(-10, 0, 0) rotate(0deg) scale(0.9, 0.9);
    }

    50% {
        transform: translate3d(10, 500px, 0) rotate(-490deg) scale(1, 1);
    }

    100% {
        transform: translate3d(30px, 1100px, 0) rotate(-880deg) scale(1.1, 1.1);
        opacity: 0;
    }
}

$svg-data: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 341 305'%3E%3Cpath fill='%23f05f40' d='M313 162L171 305 28 163C11 146 0 122 0 95 0 43 43 0 95 0c31 0 58 15 76 37C188 15 215 0 246 0c52 0 95 43 95 95 0 26-11 50-28 67z'/%3E%3C/svg%3E");
/* different sizes, and default origins based on size */
#flakes i:nth-of-type(4n) {
    height: 30px;
    width: 30px;
    transform-origin: right -30px;
    -moz-transform-origin: right -30px;
}

#flakes i:nth-of-type(4n + 1) {
    height: 24px;
    width: 24px;
    transform-origin: left -30px;
    -moz-transform-origin: left -30px;
}

#flakes i:nth-of-type(4n + 2) {
    height: 10px;
    width: 10px;
    transform-origin: -30px 0;
    -moz-transform-origin: -30px 0;
}

/* different speeds, with iteration counts to equal 3minutes approx. */
#flakes i:nth-of-type(4n) {
    animation-duration: 15.3s;
    animation-iteration-count: 1;
    transform-origin: -10px -20px;
    -moz-animation-duration: 5.3s;
    -moz-animation-iteration-count: 12;
    -moz-transform-origin: -10px -20px;
    //	transform: translate3d(30px, 1100px, 0) rotate(-40deg) scale(1.1, 1.1);
}

#flakes i:nth-of-type(4n + 1) {
    animation-duration: 13.1s;
    animation-iteration-count: 1;
    transform-origin: 10px -20px;
    -moz-animation-duration: 3.1s;
    -moz-animation-iteration-count: 20;
    -moz-transform-origin: 10px -20px;
    //	transform: translate3d(30px, 1100px, 0)  rotate(20deg) scale(1.1, 1.1);
}

#flakes i:nth-of-type(4n + 2) {
    animation-duration: 11.7s;
    animation-iteration-count: 1;
    transform-origin: right -20px;
    -moz-animation-duration: 1.7s;
    -moz-animation-iteration-count: 35;
    -moz-transform-origin: right -20px;
    //	transform: translate3d(30px, 1100px, 0)  rotate(-20deg) scale(1.1, 1.1);
}

/* playing with timing functions to make it a teeny bit more random like */
#flakes i:nth-of-type(5n) {
    animation-timing-function: ease-in-out;
}

#flakes i:nth-of-type(5n + 1) {
    animation-timing-function: ease-out;
}

#flakes i:nth-of-type(5n + 2) {
    animation-timing-function: ease;
}

#flakes i:nth-of-type(5n + 3) {
    animation-timing-function: ease-in;
}

#flakes i:nth-of-type(5n + 4) {
    animation-timing-function: linear;
}

#flakes i:nth-of-type(11n) {
    animation-timing-function: cubic-bezier(0.2, 0.3, 0.8, 0.9);
}

// #flakes i:nth-of-type(5n) {
// 	-moz-animation-timing-function: ease-in-out;
// }

// #flakes i:nth-of-type(5n+1) {
// 	-moz-animation-timing-function: ease-out;
// }

// #flakes i:nth-of-type(5n+2) {
// 	-moz-animation-timing-function: ease;
// }

// #flakes i:nth-of-type(5n+3) {
// 	-moz-animation-timing-function: ease-in;
// }

// #flakes i:nth-of-type(5n+4) {
// 	-moz-animation-timing-function: linear;
// }

// #flakes i:nth-of-type(11n) {
// 	-moz-animation-timing-function: cubic-bezier(0.2, 0.3, 0.8, 0.9);
// }

/* playing with opacity to make it a teeny bit more random like */
#flakes i:nth-of-type(7n) {
    opacity: 0.5;
}

#flakes i:nth-of-type(7n + 2) {
    opacity: 0.3;
}

#flakes i:nth-of-type(7n + 4) {
    opacity: 0.7;
}

#flakes i:nth-of-type(7n + 6) {
    opacity: 0.6;
    animation-timing-function: ease-in;
    transform-origin: left 10px;
    -moz-animation-timing-function: ease-in;
    -moz-transform-origin: left 10px;
}

#flakes i:nth-of-type(7n + 1) {
    opacity: 0.8;
}

#flakes {
    margin: 0 auto;
    margin-top: -60px;
    width: 100%;
    text-align: center;
    position: fixed;
    top: -30px;
    left: 0px;
    width: 100% !important;
 
    i {
        display: inline-block;
        background-repeat: no-repeat;
        background-image: $svg-data;
        background-size: 100%;
        height: 16px;
        width: 16px;
        transform-origin: left -20px;
        animation-duration: 12s;
        animation-direction: normal;
        animation-iteration-count: 1;
        animation-timing-function: linear;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
        animation-play-state: running;
    }
}

table .toggle-snow {
    background-position: 0px 0px !important;
}

/* Mobile Stuff & Fallback Activation  */

@media only screen and (min-width: 1120px) {
    .flakes {
        margin-top: -30px !important;
    }
}

@media only screen and (max-width: 599px) {

    .w-100,
    .snow-footer {
        width: 100% !important;
        height: auto !important;
    }

    .no-bg {
        background: #ffffff !important;
    }

    .flakes {
        display: block !important;
        width: 400px !important;
        margin-top: -90px !important;
    }
}

@media only screen and (max-width: 400px) {

    .w-100,
    .snow-footer {
        width: 100% !important;
        height: auto !important;
    }

    .no-bg {
        background: #ffffff !important;
    }

    .flakes {
        display: block !important;
        width: 260px !important;
        margin-top: -120px !important;
    }
}

@media screen and (min-device-pixel-ratio: 0) {
    .fallback {
        display: none !important;
    }

    .interactive {
        display: block !important;
        max-height: none !important;
    }

    /*
        Selective blocking for clients.
        - Samsung (#MessageViewBody) - no absolute positioning
        - Comcast/Zimbra (body.MsgBody) - inconsistent CSS support
        - Add .cbox to ensure CSS specificity weight exceeds earlier block
        */
    #MessageViewBody .cbox+.fallback-wrapper,
    body.MsgBody .cbox+.fallback-wrapper {
        display: block !important;
    }

    #MessageViewBody .cbox+.kinetic-wrapper,
    body.MsgBody .cbox+.kinetic-wrapper {
        display: none !important;
    }

    .flakes {
        display: block !important;
    }
}