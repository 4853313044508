.rate-container {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 4px;

  .show-rate {
    margin-left: 0px;
    padding: 15px;
  }

  .calcholder {
    margin-right: 20px;
    margin-left: auto;
    cursor: pointer;

      svg {
    height: 34px;
    width: 35px;
    	stroke: $c_green !important;
    g {

	fill: $c_green !important;
    }
  }
//      rect {
//     // fill: $c_green;
//     stroke: $c_green;
//     &#fillup {
//       fill: $c_green;
//     }
// }

  }

  .btn-holder {
    // width: 130px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    overflow: hidden;
    width: fit-content;

    .btn-pill {
      margin-bottom: 0;
    }

    button {
      margin: 0 auto;
    }
  }
}

.notify-padding {
 padding: 16px 20px;
}
.border-radius {
   border-radius: 4px;
}

.spinning-loader {
  background: transparent;
  animation: rotate-s-loader 1s linear infinite;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 42px;
    height: 42px;
  }

  // margin: 6rem auto;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.show-estimates {
  text-align: left;
  max-height: calc(100vh - 200px);
// overflow-y: scroll;
// overflow-x: visible;
}

.thecalc {
  // padding-right: 20px;
  cursor: pointer;

  svg {
    height: 34px;
    width: 35px;
    	stroke: $c_accent_2;
    g {

	fill: $c_accent_2;
    }
  }


}

.modal-outer {
  display: flex;
  flex-direction: row;
 flex: 1 1 100%; 
justify-content: center;
  /* margin: 30px; */
  max-height: 100%;

}


  .addressCheck {
    // margin-top: auto;

    svg {
      height: 16px;
      opacity: 1 !important;
      flex: 0 0 16px;
      padding-right: 8px;
align-self: center;
    }
  }


.printlabel {

  // .modal-outer {
  //   display: flex;
  //   flex-direction: row;
  //   //  flex: 1 1 100%;
  // }


  .close-outer {
    align-self: flex-end;
  }

  .err-field {
    border-bottom: 1px solid red !important;
    background: #ffefef;
    box-sizing: border-box;

    input.required {
      border: none !important;
    }
  }

  .imglinkwrapper {
    position: relative;
  }

  // @include respond-to(desktop, max) {
  //   padding: 0 0px;

  //   .createlabel {
  //     gap: 0px;
  //     flex-flow: column;
  //     align-items: center;
  //     // &>div {
  //     //    margin-top: 0px;
  //     //   &&:first-child {
  //     //      margin-top: 48px;
  //     //   }


  //   }
  // }

  // @include respond-to(desktop, min) {
  //   .shipping-estimate {
  //     max-width: initial;
  //   }
  // }

  // @include respond-to(large, min) {
  //   padding: 0 0px;

  //   .createlabel {
  //     //  gap: 60px;

  //     .field-wrap {
  //       flex-grow: 1;

  //       input.required {
  //         z-index: 1 !important;
  //       }
  //     }

  //     .stracker {
  //       &>.justify-start {
  //         flex-direction: column;
  //         margin-bottom: 18px;

  //         .imgtoggle {
  //           max-height: 382px;
  //           overflow: hidden;
  //           width: 211px;
  //           height: 282px;
  //         }
  //       }

  //       .field-group {
  //         .label-wrap {
  //           justify-content: flex-start;
  //           text-align: left;
  //           max-width: 83px;
  //           margin-right: 6px;
  //           text-transform: uppercase;
  //         }
  //       }
  //     }
  //   }
  // }

  .rate-modal {

    .confirmWrapper {

      // height: 100%;
      // 	display: flex;
      img {
        max-width: 100%;
        max-height: 80vh;
        margin: auto;
        height: 100%;
      }
    }

    .rate-row {
      cursor: pointer;

      &:first-child {
        border-top: 1px solid $c_lab_grey_1;
      }

      border-top: 1px solid $c_lab_grey_1;
      border-bottom: 0px solid $c_lab_grey_1;

      &:hover {
        background-color: $lab_grey_0;
      }

      &.active {
        @extend %active-green;
        border-top: 1px solid $c_green !important;
        border-bottom: 1px solid $c_green !important;

        // .tooltip {}
      }


      .service-cost {
        padding-right: 14px;
        padding-top: 14px;
      }

      .radioinput {
        width: 25px;
      }

      .tooltip {
        position: relative;
        display: flex;

        color: $c_accent_3;
        flex: 1;
        padding: 15px 0px;

        .rateimg {
          align-self: center;
          /* width: auto; */
          /* justify-content: flex-start; */
          /* justify-self: self-start; */
          /* margin-right: 20px !important; */
          margin-left: 0px;
          flex-basis: 68px;
          text-align: center;
        }

        .rate-title {
          color: black;
        }

        .tipholder {
          display: flex;
          flex-direction: row;
          // flex: 0 0 100%;

          .tooltip-anchor {
            position: relative;
            margin-left: 10px;
            // margin-top: 2px;

            svg {
              width: 15px;

              rect {
                stroke: $c_accent_3
              }

              path,
              circle {
                fill: $c_accent_3
              }

            }

            .tooltiptext {
              visibility: hidden;
              width: 300px;
              background-color: #f6f6f6;
              color: black;
              /* text-align: center; */
              border-radius: 6px;
              padding: 10px;
              position: absolute;
              z-index: 1;
              top: -60px;

              border: 2px solid grey;
              object-position: center;
              /* max-width: 100%; */
              /* width: 100%; */
              /* height: auto; */
              /* top: 50%; */
              left: 50%;
              transform: translate(-50%, -50%);


              .up-arrow:before {
                content: "";
                display: block;
                position: absolute;
                left: calc(50% - 12px);
                top: 100%;
                width: 0;
                height: 0;
                border: 12px solid transparent;
                border-top-color: grey;
                margin-top: 0px;
              }

              .up-arrow:after {
                content: "";
                display: block;
                position: absolute;
                left: calc(50% - 10px);
                top: 100%;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-top-color: #f6f6f6;
                margin-top: -1px;

              }

            }

            &:hover {
              .tooltiptext {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  .h5-20 {
    font-family: $f_h4;
    font-weight: 400;

  }



  .field-wrap {
    // input {
    //   z-index: unset !important;
    // }

    .label-wrap {
      z-index: 3;
    }
  }

  .form-section-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }

    .editable>svg {
      padding-right: 0px;
      width: 30px;
      height: 26px;
    }
  }

  .shipping-estimate {

    .modal-outer {
      flex: 1 1 100%;
      max-width: 600px;
    }

    .tooltip-anchor {
      svg {
        // padding-right: 15px;
        margin-bottom: -2px;
        width: 30px;
        height: 26px;
      }
    }

    .size-qty-wrapper {
      // input.required {}

      .field-group:first-child .label-wrap {
        min-width: fit-content !important;
        z-index: 3
      }
    }
  }

  .hf-warning {
    background: #ffd1d180
  }
}

.rselect {
  // flex-basis: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  display: flex;

  .selectField {

    // .react-select__dropdown-indicator {
    //   // border-left: 1px solid $c_lab_grey_1;
    // }

    .react-select__control {
      z-index: 3;
      /* background: white; */
      position: relative;
    }

    .react-select__menu {
      z-index: 4;
    }

  }
}

.prod-categories {
  .field-group {
    .label-wrap {
      flex: 1 0 100%;
    }
  }
}