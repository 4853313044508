.order-filters {
  @include flex(row, center, start);
  margin-bottom: 10px;
  padding-left: 20px;
  margin: 0 -30px;
  padding-right: 20px;
  border-bottom: 1px solid $c_primary;

  // transition: opacity 0.3s ease-in-out;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 20px 15px white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 1px;
  }

  &.sticky {
    top: 47px;
    background: white;
    z-index: 8;

    &::after {
      opacity: 1;
    }
  }

  .order-funnel-label {
    cursor: pointer;
    padding: 15px 15px;
    margin: 0 2px;
    border: 1px solid #c3c3c3;
    border-bottom-color: #ffffff;
    font-size: 18px;
    font-weight: 100;
    flex: 2 1;
    text-align: center;
    font-family: barlow condensed;
    font-weight: 400;

    span {
      color: $c_accent;
    }

    .red {
      color: $c_accent;
    }
  }



  &.pendingConfirmation {
    .pendingConfirmation {
      margin-bottom: -1px;
      border: 1px solid $c_primary;
      border-bottom-color: $c_secondary;
      border-bottom-width: 2px;
    }
  }

  &.pendingLabel {
    .pendingLabel {
      margin-bottom: -1px;
      border: 1px solid $c_primary;
      border-bottom-color: $c_secondary;
      border-bottom-width: 2px;
    }
  }

  &.pendingFulfilled {
    .pendingFulfilled {
      margin-bottom: -1px;
      border: 1px solid $c_primary;
      border-bottom-color: $c_secondary;
      border-bottom-width: 2px;
    }
  }
}

.react-toggle,
.form {
  border: none !important;
}

.orders {
  // @include flex(col, center, start);
  position: relative;
  // padding: 0 20px;
  padding-top: 32px;

  .orders-inner {

    // @include flex(wrap, start, center);
    // width: 100%;
    &.hidden {
      display: none !important;
    }
  }

  .funnel-header {
    @include flex(col, center, center);
    display: block;
    width: 100%;

    text-align: center;
    font-size: 18px;

    p {
      font-size: 18px;
    }

    a {
      @extend %btn-pill;
      // margin-bottom: 20px;
    }
  }

  .clear-all-orders {
    margin-top: 50px;
  }


  .order {
    @include flex(col, center, start);
    // width: 50%;
    // padding: 5px;

    box-sizing: border-box;
    font-family: $f_h5;
    // font-size: 16px !important;
    // line-height: 1em;
    font-weight: 400 !important;
    margin-left: 0px;
    overflow: hidden;
    // max-width: 330px;
    flex: 1 1 261px;

    // max-width: 50%;
    // margin-right: auto;
    // flex-grow: 1;
    .btn-pill {
      margin-bottom: 12px;
    }

    &.highlight {
      &::before {
        content: 'Review';
        @include flex(row, center, center);
        position: absolute;
        padding: 0px 3px;
        margin-left: 4px;
        top: 0px;
        left: 0px;
        color: $c_secondary;
        width: 74px;
        height: 30px;
        background: $c_accent;
        z-index: 1;
      }
    }

    &.problem {
      // border: 2px solid $c_accent;
      border-radius: 0px;

      &::before {
        content: 'Resolve';
        @include flex(row, center, center);
        position: absolute;
        padding: 0px 3px;
        margin-left: 4px;
        top: 0px;
        left: 0px;
        color: $c_secondary;
        width: 74px;
        height: 30px;
        background: $c_accent;
        z-index: 1;
      }

      // .opacity04 {
      //   opacity: 0.4;
      // }
    }



    &.checked {

      .react-toggle .react-toggle-track,
      .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #f05f40;
      }

      .react-toggle .react-toggle-thumb {
        left: 27px;
        border: 1px solid #f05f40;
      }
    }

    &:not(.checked) {

      .react-toggle .react-toggle-track,
      .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #4d4d4d;
      }

      .react-toggle .react-toggle-thumb {
        left: 1px;
        border: 1px solid #4d4d4d;
      }
    }

    .product-title {
      font-size: 20px;
      margin-bottom: 12px;
      line-height: 1em;
    }

    .product-oPrice {
      font-size: 16px !important;
      font-weight: 300 !important;

      .money.small {
        padding: 0;

        .dollar-sign {
          font-size: 16px !important;
          font-weight: 300 !important;
        }

        .dollar-amount {
          font-size: 16px !important;
          font-weight: 300 !important;
        }

        .cents-amount {
          font-size: 16px !important;
          font-weight: 300 !important;
        }
      }
    }

    .product-image {
      width: 100%;
      height: 230px;
      position: relative;

      &.order-badge {
        svg {
          width: 41px;
          height: 36px;
          right: 6px;
          top: 6px;
          position: absolute;
        }
      }
    }

    .order-name {
      line-height: 20px;
    }

    .order-address-wrapper {
      font-size: 16px;
      line-height: 20px;

      .order-address_line1.h6 {
        font-size: 16px;
        line-height: 20px;
      }
    }



    .form {
      width: 100%;
      max-width: 100%;
      margin: 0;

      .field-wrap {
        padding: 10px;
        @extend %greyline;
      }
    }

    .deleteOrder {
      align-self: center;
      -webkit-align-self: center;
    }
  }

  .order-row {
    width: 100%;
    padding-bottom: 12px;
    padding-top: 10px;
    line-height: 1.3em;
    @extend %greyline;
  }
}

.order {
  width: 100%;

  .order-image {
    width: 100%;
    height: 230px;
  }



  // .btn-pill {
  //     padding: 10px 12px !important;
  //     line-height: 20px !important;
  //     padding-bottom: 9px !important;
  // }

  // .cancel-btn {
  //     width: 42px;
  //     height: 42px;
  //     cursor: pointer;

  // }


  // svg.doublebtn:hover g path {
  //     stroke: $c_accent;
  // }
}

.prendingConfirmation {

  .btn-pill {
    &.light {
      border: 1px solid $c_lab_grey_1 !important;
    }

    &.muted {
      border: 1px solid $c_lab_grey_1 !important;
      @extend %title_sm_3;
      font-weight: 500 !important;
      padding: 0px 20px !important;
    }
  }

  .status-undefined {
    .doublebtn {
      background-color: $c_labgrey;
    }
  }

  .status-confirmed {
    .checkmark {
      background-color: $c_labgreen25;
    }
  }

  .status-canceled {
    .cancel-btn {
      background-color: $c_labred;
    }
  }


  svg.doublebtn,
  .btn-two-pill {
    background: #ffffff;
    border: 2px solid #000000 !important;
    color: #000000;
    padding: 7px 0px !important;
    line-height: 1em !important;
    cursor: pointer;
    height: 15px;
    margin: 0 !important;
    width: 75px;
    align-items: center;
    justify-content: center;
    display: flex;


    &.checkmark {
      border-radius: 2em 0em 0em 2em !important;
      border-right-width: 0px !important;
      // width: 29%;

      &:hover g path {
        stroke: $c_toggle;
      }
    }

    &.cancel-btn {
      border-radius: 0em 2em 2em 0em !important;
      // width: 30%;

      &:hover g path {
        stroke: $c_link;
      }
    }
  }

  .btn-two-pill {
    width: 100%;

  }
}

// for the payment section 

.pay-method {
  // @include flex(row, center, start);
  // padding: 80px 0 60px;

  .boxholder {
    /* width: 100%; */
    /* flex: 1 1 50%; */
    /* flex-direction: column; */
    display: flex;

    align-items: center;
    align-content: space-around;
    // padding: 25px 0 25px;
    height: 40px;
  }


  [type="radio"] {

    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;

      &+label {
        position: relative;
        padding-left: 2.5em;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          border: 2px solid $c_primary;

          // border-radius: 4px;
        }

        &::after {
          content: "\2713";
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, "Helvetica Neue", Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
          position: absolute;
          top: 0;
          left: 0;
          font-size: 34px;
          line-height: 0.4;
          transition: all 0.2s;
        }
      }
    }

    &:not(:checked) {
      &+label {
        &::after {
          opacity: 0;
          transform: scale(0);
        }
      }
    }

    &:checked {
      &+label {
        &::before {
          background: $c_primary;
        }

        &::after {
          opacity: 1;
          transform: translate(0px, 8px) scale(0.8);
          color: white;
        }
      }
    }
  }

  label {
    @extend%t_body_sm;
    margin-top: -1.53em;
  }

  input {
    z-index: 1;
    width: unset;
    margin-right: 23px;
    width: 29px;
    height: 22px;
    outline: 2px solid black;
    outline-offset: -1px;

    &:focus {
      width: 30px !important;
      height: 22px !important;
      outline: 2px solid black !important;
      outline-offset: -1px !important;
    }
  }

  // span {
  //     @extend %t_body_xs;
  // }
}

.icon-container,
.order-instagram {
  a {
    max-width: 142px;
    box-sizing: border-box;
    overflow: hidden;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-top: 4px;
    // padding-right: 8px;
    align-self: center;
  }
}

.plusminus {
  // border-bottom: 1.5px solid $c_lab_grey_1 !important;
}

.commholder {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  .plusminuswrap {
    border: 1px solid $c_lab_grey_1 !important;
    /* width: 20px; */
    font-weight: 200 !important;
    font-family: sans-serif !important;
    border-radius: 25px;
    min-width: 110px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-evenly;
    font-size: 15px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    color: black;
    line-height: 35px;
    margin-right: 0px;
    margin-left: 0px;

    span {
      font-family: "Barlow Semi Condensed", sans-serif;
      font-size: 15px;
      font-weight: 500;
      width: 30px;
      text-align: center;
      color: $c_accent_3;
    }

    i {
      cursor: pointer;
      font-style: normal;
      line-height: 30px !important;
      height: 100%;
      width: 20px;
      justify-content: center !important;

      font-weight: 200 !important;
      font-family: sans-serif !important;
      align-items: center;
      font-size: 28px;
      // font-family: "Barlow Semi Condensed", sans-serif;
      text-align: center;

      // &:hover {
      // 	color: black;
      // }
      &.disabled {
        color: $c_lab_grey_1;
      }
    }
  }

  button {
    margin-right: 40px;
  }

  &.has-delete {
    .plusminuswrap {
      margin-right: 40px;
    }
  }
}

.gap-10 {
  gap: 10px;
}

// conversations
.chat-container {
  display: flex;
  flex-direction: column;

  // height: 400px;
  .avita-imageholder {
    font-size: 18px !important;
    margin-top: 0px;
  }


  .messages {
    flex-grow: 1;
    // overflow-y: auto;
    margin-bottom: 10px;

    .msg-wrapper {
      max-width: 70%;

      p {
        margin-bottom: 0px
      }

      .chat-avitar {
        border-radius: 50%;
        height: 32px;
        width: 32px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $lab_grey_0;
        margin-right: 5px;
        display: flex;
        padding: 0px;
        box-sizing: border-box;
        flex: 1 0 32px;

      }

      .message {
        padding: 12px 16px;
        margin-bottom: 5px;
        border-radius: 5px;
        word-wrap: break-word;
        white-space: wrap;
      }

      &.sent {
        align-self: flex-end;
        color: black;
        margin-left: auto;

        .message {
          background: $c_light_green;
        }
      }

      &.received {
        align-self: flex-start;

        .message {
          background-color: #f1f1f1;
        }
      }


    }
  }





  .message-input {
    display: flex;

    .msg-btn {
      margin-top: 15px;
    }

    textarea {
      padding: 0px;
      padding-left: 10px;
    }

    .card-preview-wrapper {
      margin-top: 15px;
      border-radius: 8px;
      flex-direction: row;
      padding-left: 40px;
      padding-bottom: 5px;
      padding-right: 5px;
        display: inline-block;
        position: relative;
      /* width: 100%; */
      /* flex-grow: 1; */
      img {
        width: 100%;
      }

      .remove-button {
        position: absolute;
        top: 8px;
          right: 13px;
        background: $c_accent_3;
        color: white;
        border: none;
        border-radius: 50%;
        border: 2px solid white;
        width: 32px;
        height: 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        
        svg {
          width: 12px;
          height: 12px;
          g {
            stroke-width: 4;
              stroke: white;
          }
        }
      }

      .remove-button:hover {
      opacity: 0.8;
      }
    }

    .file-add {
      .plus-btn {
        text-align: center;
        display: flex;
      }

      input[type="file"] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        &+label {
          cursor: pointer;
          padding: 0;
          z-index: 1;
        }
      }
    }


    .greencircle {
      margin-top: 15px;
      background: $c_lab_grey_1;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      color: white;
      text-align: center;
      // line-height: 40px;
      font-weight: 500;
      margin: 0 auto;
      cursor: pointer;

      &:active {
        // background: $c_green;
        opacity: 0.8;
      }

      &.green {
        background: $c_green;
      }

      &:disabled {
        background: $c_lab_grey_1 // color: $c_lab_grey_1;
      }
    }

    button {
      &.disabled {
        color: $c_lab_grey_1 !important;
      }
    }

    input {
      flex-grow: 1;

      border-top: 1px solid #ccc;
      border-radius: 5px;
    }


  }
}


.green-msg-box {
  background: $c_light_green;
  color: $c_green;
  /* Frame 654 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  gap: 10px;
  border-radius: 4px;
  width: 100%;

  span {
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
}

.green-msg-box-sm {
  background: $c_light_green;
  color: black;
  /* Frame 654 */

  /* Auto layout */
  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 8px;
  gap: 10px;
  border-radius: 4px;

  span {
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
}

/* Comment */
.greenbox {
  /* Auto layout */
  // display: flex;
  // flex-direction: row;
  // align-items: flex-start;
  // padding: 8px;
  gap: 10px;

  width: 100%;
  // height: 34px;

  background: rgba(59, 155, 21, 0.15);
  border-radius: 4px;

  div {
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
}

.white-msg-box-sm {
  background: white;
  color: $c_accent;
  /* Frame 654 */

  /* Auto layout */
  display: flex;
  flex-direction: row;

  align-items: center;
  // padding: 8px;
  gap: 10px;
  border-radius: 4px;

  svg {
    align-self: self-start;
    margin-top: 5px;
  }

}


.modalwrapper {
  h3 {
    margin-bottom: 4px;
  }
}