	.admintrackerp {
			.pagination {
			width: 100vw;
  position: sticky;
  left: 0px;
			}
}

	.pagination {

		justify-content: center;
    	button {
		background: #fff !important;
		border: 1px solid #000 !important;
		border-radius: 2em 2em 2em 2em !important;
		color: #000 !important;
		line-height: 2em !important;
		padding: 0px 10px;
	}
		.pageview {
			color: $c_accent_3;

			>div {
				display: inline;
			}

			>span {
				cursor: pointer;
				border-right: 1px solid $c_accent_3;
				padding: 0px 15px !important;

				&:last-of-type {
					border: none;
				}
			}


		}

		// margin-top: 30px;
		.pagholder {
			border-color: $c_lab_grey_1 !important;
			font-family: $f_body;
			font-size: 16px;
			padding: 2px 10px 3px 10px !important;
			height: 34px;

			button {
				border: none !important;
				padding: 0 !important;

				svg {
					margin-right: 0px;
					margin-left: 0px;
				}
			}

			button:first-of-type {
				svg {
					transform: rotate(-90deg);
				}
			}

			button:last-of-type {
				svg {
					transform: rotate(90deg);
				}

			}

			>span {
				padding: 5px 30px;
				display: block;
				box-sizing: content-box;

				font-family: "Barlow Semi Condensed", sans-serif;
				font-size: 16px;
				font-weight: 500;
			}
		}
	}