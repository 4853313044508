.login_wrapper {
    // width: 303px;
    margin: 0 auto;

    .login-form,
    .error-message {
        font-size: 16px;
        line-height: 18px;
        // ul {
        //     li:before {
        //         content: "X ";
        //         }
        // }
        @include flex(col, center, center);

        .label-wrap {
            label {
                white-space: break-spaces;
                line-height: 1em;
            }
        }

        .seller-toggle {
            margin: 10px 0;
        }

        .buyer-login-link {
            font-size: 20px;
            line-height: 28px;
        }

        .field-group {
            width: 100%;

            .field-wrap {
                input {
                    z-index: 1;
                    padding: 24px 0 16px 10px;
                }
            }
        }

        .login-link {
            span {
                font-weight: 500;
            }


            &.seller-login-link,
            &.shopper-login-link {
                font-size: 16px;
                line-height: 18px;
                text-transform: uppercase;

                // margin-top: 100px;
                a {
                    color: $c_accent_3;
                    @extend %title_sm_2;
                    font-weight: 500;
                }
            }
        }
    }

    i {
        position: absolute;
        right: 0px;
        z-index: 2;
        bottom: 0px;
        line-height: 1em;
        top: 0px;
        display: flex;
        align-items: center;
    }

    i:hover {
        color: $c_accent;
        cursor: pointer;
    }

    ul.errorbox {
        li {
            color: red !important;
            padding-left: 14px;
            margin-bottom: 2px;

            &::marker {
                content: "\2715";
                font-size: 16px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                position: absolute;
                top: 0;
                left: 0;
                /* font-size: 40px; */
                /* line-height: 0.4; */
                transition: all .2s;
                font-weight: 500;
            }
        }

        li.green {
            color: #75B857 !important;

            &::marker {
                content: "\2713";

            }

        }
    }




    &.pw-reset {

        form,
        .form {
            max-width: 450px;

            .field-group {
                .field-wrap {
                    flex: 2 1;
                }
            }
        }
    }

    &.pw-reset-confirm {
        .page-content {
            max-width: 350px;
            margin: 0 auto;
            text-align: center;
        }
    }

}